<template>
    <v-layout v-resize="onResize" class="d-flex flex-column">
        <v-sheet color="white" class="px-4 rounded-t">
            <div class="d-flex align-center pt-2 pb-4">
                <div class="d-flex align-start mr-4">
                    <v-icon x-large color="primary" >
                        {{ tableIcon }}
                    </v-icon>
                </div>
                <span class="text-h6 font-weight-regular">{{ tableName }}</span>

                <v-spacer></v-spacer>

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn color="primary" class="mr-4 pa-0"
                            v-on="on"
                            min-width="48px"
                            @click="refreshGrid()"
                            :disabled="noEventSelected || noDisplayFilterSelected || noYearReferenceSelected"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Atualizar</span>
                </v-tooltip>

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn color="primary" class="mr-4 pa-0"
                            v-on="on"
                            min-width="48px"
                            @click="showTab = !showTab"
                            :disabled="noAnalyseHeaders"
                        >
                            <v-icon>{{ showTab ? 'mdi-eye-off' : 'mdi-eye' }}</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ showTab ? 'Ocultar filtros' : 'Exibir filtros' }}</span>
                </v-tooltip>

                <v-dialog v-model="dialogRefreshGrid" max-width="850px">
                    <v-card>
                        <div class="d-flex justify-center">
                            <v-card-title class="headline">Você possui edições não salvas. Tem certeza de que deseja continuar?</v-card-title>
                        </div>
                        <v-card-actions class="px-6 pb-6">
                            <v-spacer></v-spacer>
                            <v-btn color="primary" text @click="dialogRefreshGrid = false" class="mr-4">Cancelar</v-btn>
                            <v-btn color="secondary" text @click="refresh(false)" class="mr-4">Continuar sem salvar</v-btn>
                            <v-btn color="primary" @click="refresh(true)">Salvar e continuar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn class="mr-4 pa-0"
                            @click="openSaveDialog()"
                            v-on="on"
                            min-width="48px"
                            :color="unsavedChanges ? 'secondary' : 'primary'"
                            :disabled="noAnalyseHeaders"
                            :loading="saving"
                        >
                            <v-icon> {{ unsavedChanges ? 'mdi-content-save-alert' : 'mdi-content-save' }}</v-icon>
                        </v-btn>
                    </template>
                    <span>Salvar</span>
                </v-tooltip>

                <v-dialog v-model="saveDialog"
                    max-width="800"
                    style="z-index: 2000 !important;"
                    persistent
                >
                    <v-card style="position: relative;">
                        <v-toolbar dark elevation="0"
                            :style="`background: linear-gradient(90deg, ${$vuetify.theme.themes.light.primary} 0%, ${$vuetify.theme.themes.light.secondary} 100%)`"
                            style="position: sticky; top: 0; width: 100%; z-index: 1000;" 
                        >
                            <v-toolbar-title>Assistente de relatório dinâmico</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-btn color="transparent" class="mt-2"
                                        v-on="on"
                                        min-width="48px"
                                        @click="closeSaveDialog()"
                                        depressed
                                    >
                                        <v-icon class="white--text">mdi-close</v-icon>
                                    </v-btn>
                                </template>
                                <span>Fechar</span>
                            </v-tooltip>
                        </v-toolbar>

                        <v-stepper v-model="step">
                            <v-stepper-header>
                                <v-stepper-step :complete="step > 1" step="1">
                                    Descrição do relatório
                                </v-stepper-step>
                                <v-divider></v-divider>
                                <v-stepper-step :complete="step > 2" step="2">
                                    Descrição do slide
                                </v-stepper-step>
                                <v-divider></v-divider>
                                <v-stepper-step step="3">
                                    Permissões de grupo de usuários
                                </v-stepper-step>
                            </v-stepper-header>

                            <v-stepper-items>
                                <v-stepper-content step="1">
                                    <v-text-field ref="reportDescriptionInput"
                                        type="text"
                                        v-model="report.dynamic_report"
                                        class="mx-2 mb-2"
                                        label="Descrição do relatório *"
                                        :hint="reportDescriptionHint"
                                        :counter="100"
                                        :maxlength="100"
                                        persistent-hint
                                    ></v-text-field>
                                </v-stepper-content>

                                <v-stepper-content step="2">
                                    <div class="ml-2">
                                        <span class="text-caption">Descrição do relatório: </span>
                                        <span class="text-subtitle-1 font-bold">{{ report.dynamic_report }}</span>
                                    </div>
                                    <v-layout align-center justify-center wrap class="my-4">
                                        <v-flex xs12>
                                            <v-text-field
                                                type="number"
                                                :value="1"
                                                class="mx-2 my-1"
                                                label="Número sequencial do slide *"
                                                readonly
                                            ></v-text-field>
                                        </v-flex>
                                        <v-flex xs12>
                                            <v-text-field
                                                type="text"
                                                v-model="report.title"
                                                class="mx-2 my-1"
                                                label="Título do slide *"
                                                :counter="250"
                                                :maxlength="250"
                                            ></v-text-field>
                                        </v-flex>
                                        <!-- <v-flex xs12>
                                            <v-text-field
                                                type="text"
                                                v-model="report.text"
                                                class="mx-2 my-1"
                                                label="Texto estático do slide"
                                            ></v-text-field>
                                        </v-flex>
                                        <v-flex xs12>
                                            <v-text-field
                                                type="text"
                                                v-model="report.format"
                                                class="mx-2 my-1"
                                                label="Formatação do slide"
                                            ></v-text-field>
                                        </v-flex> -->
                                    </v-layout>
                                </v-stepper-content>

                                <v-stepper-content step="3">
                                    <v-layout align-center justify-center wrap>
                                        <v-flex xs12>
                                            <v-select
                                                v-model="selectAdmin"
                                                :items="admins"
                                                class="mb-4 mt-2"
                                                item-text="name"
                                                item-value="id_user"                
                                                label="Admin"
                                                @change="changeSelectedAdmin($event)"
                                                multiple
                                                chips
                                                hint="Selecione os usuários que podem visualizar e editar o relatório."
                                                persistent-hint
                                            ></v-select>
                                        </v-flex>
                                        <v-flex xs12>
                                            <v-select
                                                v-model="selectUser"
                                                :items="users"
                                                class="mt-4 mb-6"
                                                item-text="name"
                                                item-value="id_user"                
                                                label="Usuários"
                                                multiple
                                                chips
                                                hint="Selecione os usuários que podem apenas visualizar o relatório."
                                                persistent-hint
                                            >
                                                <template v-slot:prepend-item>
                                                    <v-list-item ripple @click="selectAllUsers">
                                                        <v-list-item-action>
                                                            <v-icon :color="!selectUser.length ? 'gray' : 'primary'">
                                                                {{ selectAllUsersIcon }}
                                                            </v-icon>
                                                        </v-list-item-action>
                                                        <v-list-item-content>
                                                            <v-list-item-title>Selecionar todos</v-list-item-title>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                    <v-divider class="mt-2"></v-divider>
                                                </template>
                                            </v-select>
                                        </v-flex>
                                    </v-layout>
                                </v-stepper-content>
                            </v-stepper-items>
                        </v-stepper>
                        <div class="light" style="position: sticky; bottom: 0; width: 100%;">
                            <v-divider class="mx-4"></v-divider>
                            <v-card-actions class="px-6 py-6">
                                <span class="text-caption primary--text ml-3">* Campos obrigatórios</span>
                                <v-spacer></v-spacer>
                                <v-btn text class="mr-4"
                                    color="primary"
                                    @click="step > 1 ? step-- : closeSaveDialog()"
                                    :ripple="false"
                                >
                                    <span v-if="step === '1' || step === 1">Cancelar</span>
                                    <span v-else>Voltar</span>
                                </v-btn>
                                <v-btn color="primary"
                                    @click="step < '3' ? step++ : saveReport()"
                                    :disabled="disableSaveReportButton"
                                    :loading="savingReport"
                                >
                                    <span v-if="step < 3"> Continuar </span>
                                    <span v-else> Salvar </span>
                                </v-btn>
                            </v-card-actions>
                        </div>
                    </v-card>
                </v-dialog>

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn color="primary" class="mr-4 pa-0"
                            v-on="on"
                            min-width="48px"
                            @click="openImportDialog()"
                            :disabled="noReportsFound"
                        >
                            <v-icon>mdi-folder-open</v-icon>
                        </v-btn>
                    </template>
                    <span>Abrir relatório</span>
                </v-tooltip>

                <v-dialog v-model="importDialog"
                    max-width="800"
                    style="z-index: 2000 !important;"
                    persistent
                >
                    <v-card style="position: relative;">
                        <v-toolbar
                            dark
                            :style="`background: linear-gradient(90deg, ${$vuetify.theme.themes.light.primary} 0%, ${$vuetify.theme.themes.light.secondary} 100%)`"
                            style="position: sticky; top: 0; width: 100%; z-index: 1000;" 
                        >
                            <v-toolbar-title>Abrir relatório</v-toolbar-title>
                            <v-spacer />

                            <v-tooltip top>
                                <template v-slot:activator="on">
                                    <v-btn color="transparent" class="mt-2"
                                        v-on="on"
                                        min-width="48px"
                                        @click="closeImportDialog()"
                                        depressed
                                    >
                                        <v-icon class="white--text">mdi-close</v-icon>
                                    </v-btn>
                                </template>
                                <span>Fechar</span>
                            </v-tooltip>
                        </v-toolbar>

                        <v-container>
                            <v-layout wrap>
                                <v-flex xs6>
                                    <v-select
                                        v-model="selectedReport"
                                        :items="reports"
                                        @change="getSlides(selectedReport)"
                                        clearable
                                        class="mx-2 my-4"
                                        item-text="dynamic_report"
                                        item-value="id_dynamic_report"                
                                        label="Relatório"
                                        hint="Selecione o relatório"
                                        persistent-hint
                                        :disabled="reports.length < 1"
                                        :loading="gettingReports"
                                        :hide-no-data="true"
                                    ></v-select>
                                </v-flex>
                                <v-flex xs6>
                                    <v-select
                                        v-model="selectedSlide"
                                        :items="slides"
                                        clearable
                                        class="mx-2 my-4"
                                        item-text="slide_title"
                                        item-value="id_dynamic_report_slide"               
                                        label="Slide"
                                        hint="Selecione um dos slides"
                                        persistent-hint
                                        :disabled="!selectedReport"
                                        :loading="gettingSlides"
                                        :hide-no-data="true"
                                    ></v-select>
                                </v-flex>
                            </v-layout>
                        </v-container>
                        <div class="light" style="position: sticky; bottom: 0; width: 100%;">
                            <v-divider class="mx-4"></v-divider>
                            <v-card-actions class="px-6 py-6">
                                <v-spacer></v-spacer>
                                <v-btn color="primary" text
                                    class="mr-4"
                                    @click="closeImportDialog()"
                                >
                                    Cancelar
                                </v-btn>
                                <v-btn color="primary"
                                    @click="setImportReport()"
                                    :loading="loading"
                                >
                                    Abrir
                                </v-btn>
                            </v-card-actions>
                        </div>
                    </v-card>
                </v-dialog>

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn class="mr-4 pa-0"
                            @click="saveSlideDialog = true, getReports()"
                            v-on="on"
                            min-width="48px"
                            color="primary"
                            :disabled="noAnalyseHeaders || noReportSelected"
                        >
                            <v-icon>mdi-folder-plus</v-icon>
                        </v-btn>
                    </template>
                    <span>Salvar novo slide</span>
                </v-tooltip>
                
                <v-dialog v-model="saveSlideDialog"
                    max-width="800"
                    style="z-index: 2000 !important;"
                    persistent
                >
                    <v-card style="position: relative;">
                        <v-toolbar
                            dark
                            :style="`background: linear-gradient(90deg, ${$vuetify.theme.themes.light.primary} 0%, ${$vuetify.theme.themes.light.secondary} 100%)`"
                            style="position: sticky; top: 0; width: 100%; z-index: 1000;" 
                        >
                            <v-toolbar-title>Salvar novo slide</v-toolbar-title>
                            <v-spacer />

                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-btn color="transparent" class="mt-2"
                                        v-on="on"
                                        min-width="48px"
                                        @click="saveSlideDialog = false"
                                        depressed
                                    >
                                        <v-icon class="white--text">mdi-close</v-icon>
                                    </v-btn>
                                </template>
                                <span>Fechar</span>
                            </v-tooltip>
                        </v-toolbar>

                        <v-container>
                            <div v-if="report" class="ml-2 mt-2">
                                <span class="text-caption">Descrição do relatório: </span>
                                <span class="text-subtitle-1 font-bold">{{ report ? report.dynamic_report : '' }}</span>
                            </div>

                            <v-layout v-else wrap>
                                <v-flex xs12>
                                    <v-select
                                        v-model="saveSlideSelectedReport"
                                        :items="reports"
                                        clearable
                                        class="mx-2 my-4"
                                        item-text="dynamic_report"
                                        item-value="id_dynamic_report"                
                                        label="Relatório"
                                        hint="Selecione o relatório"
                                        persistent-hint
                                        :disabled="reports.length < 1"
                                        :loading="gettingReports"
                                        :hide-no-data="true"
                                    ></v-select>
                                </v-flex>
                            </v-layout>

                            <v-layout align-center justify-center wrap class="my-4">
                                <v-flex xs12>
                                    <v-text-field
                                        type="number"
                                        v-model.number="reportNewSlide.number"
                                        class="mx-2 my-1"
                                        label="Número sequencial do slide *"
                                        :hint="slides.length > 1 ? `O relatório atual possui ${slides.length} slides` : `O relatório atual possui ${slides.length} slide`"
                                        persistent-hint
                                    ></v-text-field>
                                </v-flex>
                                <v-flex xs12>
                                    <v-text-field
                                        type="text"
                                        v-model="reportNewSlide.title"
                                        class="mx-2 my-1"
                                        label="Título do slide *"
                                        :counter="250"
                                        :maxlength="250"
                                    ></v-text-field>
                                </v-flex>
                                <!-- <v-flex xs12>
                                    <v-text-field
                                        type="text"
                                        v-model="reportNewSlide.text"
                                        class="mx-2 my-1"
                                        label="Texto estático do slide"
                                    ></v-text-field>
                                </v-flex>
                                <v-flex xs12>
                                    <v-text-field
                                        type="text"
                                        v-model="reportNewSlide.format"
                                        class="mx-2 my-1"
                                        label="Formatação do slide"
                                    ></v-text-field>
                                </v-flex> -->
                            </v-layout>
                        </v-container>

                        <div class="light" style="position: sticky; bottom: 0; width: 100%;">
                            <v-divider class="mx-4"></v-divider>
                            <v-card-actions class="px-6 py-6">
                                <span class="text-caption primary--text ml-3">* Campos obrigatórios</span>
                                <v-spacer></v-spacer>
                                <v-btn color="primary" text
                                    class="mr-4"
                                    @click="saveSlideDialog = false" 
                                >
                                    Cancelar
                                </v-btn>
                                <v-btn color="primary"
                                    @click="newSlide(), saveSlideDialog = false"
                                    :disabled="disableSaveSlideButton"
                                >
                                    <span>Salvar</span>
                                </v-btn>
                            </v-card-actions>
                        </div>
                    </v-card>
                </v-dialog>

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn class="mr-4 pa-0"
                            @click="copySlideDialog = true, getReports()"
                            v-on="on"
                            min-width="48px"
                            color="primary"
                            :disabled="!selectedSlide"
                        >
                            <v-icon>mdi-content-copy</v-icon>
                        </v-btn>
                    </template>
                    <span>Copiar slide</span>
                </v-tooltip>

                <v-dialog v-model="copySlideDialog"
                    max-width="800"
                    style="z-index: 2000 !important;"
                    persistent
                >
                    <v-card style="position: relative;">
                        <v-toolbar
                            dark
                            :style="`background: linear-gradient(90deg, ${$vuetify.theme.themes.light.primary} 0%, ${$vuetify.theme.themes.light.secondary} 100%)`"
                            style="position: sticky; top: 0; width: 100%; z-index: 1000;" 
                        >
                            <v-toolbar-title>Copiar slide</v-toolbar-title>
                            <v-spacer />

                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-btn color="transparent" class="mt-2"
                                        v-on="on"
                                        min-width="48px"
                                        @click="copySlideDialog = false"
                                        depressed
                                    >
                                        <v-icon class="white--text">mdi-close</v-icon>
                                    </v-btn>
                                </template>
                                <span>Fechar</span>
                            </v-tooltip>
                        </v-toolbar>
                        <v-container>
                            <v-layout wrap>
                                <v-flex xs12>
                                    <v-autocomplete
                                        v-model="copySlideSelectedReport"
                                        :items="reports"
                                        clearable
                                        class="mx-2 mt-2 mb-4"
                                        item-text="dynamic_report"
                                        item-value="id_dynamic_report"             
                                        label="Relatório *"
                                        hint="Selecione para qual relatório irá o slide"
                                        persistent-hint
                                        :loading="gettingReports"

                                    ></v-autocomplete>
                                </v-flex>
                            </v-layout>
                        </v-container>
                        <div class="light" style="position: sticky; bottom: 0; width: 100%;">
                            <v-divider class="mx-4"></v-divider>
                            <v-card-actions class="px-6 py-6">
                                <span class="text-caption primary--text ml-3">* Campos obrigatórios</span>
                                <v-spacer></v-spacer>
                                <v-btn color="primary" text
                                    class="mr-4"
                                    @click="copySlideDialog = false"
                                >
                                    Cancelar
                                </v-btn>
                                <v-btn color="primary"
                                    @click="copySlide(), copySlideDialog = false"
                                >
                                    Copiar
                                </v-btn>
                            </v-card-actions>
                        </div>
                    </v-card>
                </v-dialog>

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn class="mr-4 pa-0"
                            @click="reset()"
                            v-on="on"
                            min-width="48px"
                            color="primary"
                            :disabled="noAnalyseHeaders"
                        >
                            <v-icon>mdi-file-plus</v-icon>
                        </v-btn>
                    </template>
                    <span>Gerar novo relatório</span>
                </v-tooltip>

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn color="primary" class="mr-4 pa-0"
                            v-on="on"
                            min-width="48px"
                            :disabled="noAnalyseHeaders"
                            @click="exportSheet()"
                            :loading="exporting"
                        >
                            <v-icon>mdi-microsoft-excel</v-icon>
                        </v-btn>
                    </template>
                    <span>Exportar Excel</span>
                </v-tooltip>

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn color="primary" class="mr-4 pa-0"
                            v-on="on"
                            min-width="48px"
                            @click="drawer = true"
                            :disabled="noAnalyseHeaders || !selectedSlide"
                        >
                            <v-icon>mdi-comment-text-multiple</v-icon>
                        </v-btn>
                    </template>
                    <span>Comentários</span>
                </v-tooltip>

                <template>
                    <v-dialog v-model="dialogCloseGrid" max-width="850px">
                        <template v-slot:activator="{ on: dialog }" >
                            <v-tooltip top>
                                <template v-slot:activator="{ on: tooltip }">
                                    <v-btn color="primary" class="pa-0"
                                        v-on="{ ...tooltip, ...dialog}"
                                        min-width="48px"
                                        @click="closeGrid()"
                                    >
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                </template>
                                <span>Fechar</span>
                            </v-tooltip>
                        </template>
                        <v-card>
                            <div class="d-flex justify-center">
                                <v-card-title class="headline">Você possui edições não salvas. Tem certeza de que deseja continuar?</v-card-title>
                            </div>
                            <v-card-actions class="px-6 pb-6">
                                <v-spacer></v-spacer>
                                <v-btn color="primary" text @click="dialogCloseGrid = false" class="mr-4">Cancelar</v-btn>
                                <v-btn color="secondary" text @click="$router.push('/')" class="mr-4">Continuar sem salvar</v-btn>
                                <v-btn color="primary" @click="save(), $router.push('/')">Salvar e continuar</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </template>
            </div>

            <v-divider class="mb-2"></v-divider>

            <v-row v-if="showTab" no-gutters class="d-flex justify-start align-center mb-1" style="height: 24px !important; width: 100%;">
                <v-col class="d-flex justify-start" style="height: 24px !important;">
                    <v-tabs  
                        v-model="tab"
                        height="24px"
                    >
                        <v-tabs-slider :color="noAnalyseHeaders || noEventSelected ? 'transparent': 'primary'"></v-tabs-slider>

                        <v-tab
                            v-for="item in tabItems"
                            :key="item"
                            :disabled="noAnalyseHeaders || noEventSelected"
                        >
                            <span class="text-capitalize">{{ item }}</span>
                        </v-tab>
                    </v-tabs>
                </v-col>
            </v-row>

            <v-tabs-items v-model="tab" v-show="showTab">
                <v-tab-item>
                    <v-row no-gutters class="d-flex justify-start align-center pt-2">
                        <v-col class="d-flex justify-start">
                            <template v-if="multipleEvents">
                                <v-autocomplete
                                    name="events"
                                    label="Eventos"
                                    :items="eventItems"
                                    item-value="text"
                                    return-object
                                    class="pa-0 mr-4"
                                    @change="setSelectedEvents($event)"
                                    v-model="events"
                                    :loading="loadingEvents"
                                    multiple
                                    style="max-width: 400px !important; z-index: 1000 !important;"
                                >
                                    <template v-slot:selection="{ item, index }">
                                        <span v-if="index === 0" class="mr-2">{{ item.text }}</span>
                                        <span v-if="index === 1" class="pt-1 text-caption primary--text">{{ `(+ ${events.length - 1})`}}</span>
                                    </template>
                                </v-autocomplete>
                            </template>

                            <template v-else>
                                <v-autocomplete
                                    name="event1"
                                    label="Evento 1"
                                    :items="eventItems"
                                    item-value="text"
                                    return-object
                                    class="pa-0 mr-4"
                                    v-model="event1"
                                    :loading="loadingEvents"
                                    style="max-width: 200px !important; z-index: 1000 !important;"
                                    @change="verifySelectedEvent($event)"
                                    @click:clear="clearEvent1()"
                                    clearable
                                />

                                <v-autocomplete
                                    name="event2"
                                    label="Evento 2"
                                    :items="eventItems"
                                    item-value="text"
                                    return-object
                                    class="pa-0 mr-4"
                                    v-model="event2"
                                    :loading="loadingEvents"
                                    style="max-width: 200px !important; z-index: 1000 !important;"
                                    @change="verifySelectedEvent($event)"
                                    :disabled="!event1"
                                />
                            </template>

                            <v-dialog v-model="dialogSelectEvent" max-width="850px">
                                <v-card>
                                    <div class="d-flex justify-center">
                                        <v-card-title class="headline">Você possui edições não salvas. Tem certeza de que deseja continuar?</v-card-title>
                                    </div>
                                    <v-card-actions class="px-6 pb-6">
                                        <v-spacer></v-spacer>
                                        <v-btn color="primary" text @click="closeDialogSelectEvent()" class="mr-4">Cancelar</v-btn>
                                        <v-btn color="secondary" text @click="selectEvent(false)" class="mr-4">Continuar sem salvar</v-btn>
                                        <v-btn color="primary" @click="selectEvent(true)">Salvar e continuar</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>

                            <template v-if="variation.id === 4">
                                <div class="d-flex mt-1">
                                    <v-checkbox
                                        label="Mês"
                                        :ripple="false"
                                        class="pa-0 ma-0"
                                        v-model="year1month"
                                        @click="showAllMonths(1)"
                                        :disabled="noAnalyseHeaders"
                                    />

                                    <v-menu
                                        bottom
                                        left
                                        :close-on-content-click="false"
                                        style="z-index: 1000 !important;"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-btn
                                                icon
                                                v-on="on"
                                                x-small
                                                class="mr-4 pt-1"
                                                :disabled="noAnalyseHeaders"
                                            >
                                                <v-icon>mdi-dots-vertical</v-icon>
                                            </v-btn>
                                        </template>

                                        <v-sheet class="px-4 py-2" max-width="260px">
                                            <v-select
                                                v-model="year1months"
                                                :items="months"
                                                label="Escolha os meses"
                                                :item-text="item => item.text"
                                                @change="showMonth(1)"
                                                multiple
                                                return-object
                                            >
                                                <template v-slot:selection="{ item, index }">
                                                    <span v-if="index === 0">{{ item.text }}</span>

                                                    <span v-if="index === 1">{{ `, ${item.text}` }}</span>

                                                    <template v-if="index === 2">
                                                        <span>...</span>
                                                        <span class="grey--text text-caption pa-1">
                                                            {{ `+ ${year1months.length - 2}` }}
                                                        </span>
                                                    </template>

                                                </template>
                                            </v-select>

                                            <div class="d-flex justify-end">
                                                <v-btn small color="primary" class="ma-0 mb-2"
                                                    @click="year1month = true, showAllMonths(1)"
                                                    :disabled="year1months.length === 12"
                                                >
                                                    Selecionar todos
                                                </v-btn>
                                            </div>
                                        </v-sheet>
                                    </v-menu>

                                    <v-checkbox
                                        label="Tri"
                                        :ripple="false"
                                        class="pa-0 ma-0"
                                        v-model="year1quarter"
                                        @click="showAllQuarters(1)"
                                        :disabled="noAnalyseHeaders"
                                    />

                                    <v-menu
                                        bottom
                                        left
                                        :close-on-content-click="false"
                                        style="z-index: 1000 !important;"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-btn
                                                icon
                                                v-on="on"
                                                x-small
                                                class="mr-4 pt-1"
                                                :disabled="noAnalyseHeaders"
                                            >
                                                <v-icon>mdi-dots-vertical</v-icon>
                                            </v-btn>
                                        </template>

                                        <v-sheet class="px-4 py-2" max-width="240px">
                                            <v-select
                                                v-model="year1quarters"
                                                :items="quarters"
                                                label="Escolha os trimestres"
                                                :item-text="item => item.text"
                                                @change="showQuarter(1)"
                                                multiple
                                                return-object
                                            >
                                                <template v-slot:selection="{ item, index }">
                                                    <span v-if="index === 0">{{ item.text }}</span>

                                                    <span v-if="index === 1">{{ `, ${item.text}` }}</span>

                                                    <template v-if="index === 2">
                                                        <span>...</span>
                                                        <span class="grey--text text-caption pa-1">
                                                            {{ `+ ${year1quarters.length - 2}` }}
                                                        </span>
                                                    </template>

                                                </template>
                                            </v-select>

                                            <div class="d-flex justify-end">
                                                <v-btn small color="primary" class="ma-0 mb-2"
                                                    @click="year1quarter = true, showAllQuarters(1)"
                                                    :disabled="year1quarters.length === 4"
                                                >
                                                    Selecionar todos
                                                </v-btn>
                                            </div>
                                        </v-sheet>
                                    </v-menu>

                                    <v-checkbox
                                        label="Sem"
                                        :ripple="false"
                                        class="pa-0 ma-0"
                                        v-model="year1semester"
                                        @click="showAllSemesters(1)"
                                        :disabled="noAnalyseHeaders"
                                    />

                                    <v-menu
                                        bottom
                                        left
                                        :close-on-content-click="false"
                                        style="z-index: 1000 !important;"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-btn
                                                icon
                                                v-on="on"
                                                x-small
                                                class="mr-4 pt-1"
                                                :disabled="noAnalyseHeaders"
                                            >
                                                <v-icon>mdi-dots-vertical</v-icon>
                                            </v-btn>
                                        </template>

                                        <v-sheet class="px-4 py-2" max-width="240px">
                                            <v-select
                                                v-model="year1semesters"
                                                :items="semesters"
                                                label="Escolha os semestres"
                                                :item-text="item => item.text"
                                                @change="showSemester(1)"
                                                multiple
                                                return-object
                                            >
                                                <template v-slot:selection="{ item, index }">
                                                    <span v-if="index === 0">{{ item.text }}</span>

                                                    <span v-if="index === 1">{{ `, ${item.text}` }}</span>

                                                    <template v-if="index === 2">
                                                        <span>...</span>
                                                        <span class="grey--text text-caption pa-1">
                                                            {{ `+ ${year1semesters.length - 2}` }}
                                                        </span>
                                                    </template>

                                                </template>
                                            </v-select>

                                            <div class="d-flex justify-end">
                                                <v-btn small color="primary" class="ma-0 mb-2"
                                                    @click="year1semester = true, showAllSemesters(1)"
                                                    :disabled="year1semesters.length === 2"
                                                >
                                                    Selecionar todos
                                                </v-btn>
                                            </div>
                                        </v-sheet>
                                    </v-menu>

                                    <v-checkbox
                                        label="Ano"
                                        :ripple="false"
                                        class="pa-0 ma-0 mr-4"
                                        v-model="startingYear"
                                        @click="showYear(1)"
                                        :disabled="noAnalyseHeaders"
                                    />
                                </div>
                            </template>

                            <template v-else-if="variation.id === 6">
                                <div class="d-flex mt-1">
                                    <v-checkbox
                                        label="Mês"
                                        :ripple="false"
                                        class="pa-0 ma-0 mr-4"
                                        v-model="year1month"
                                        @click="showMonth(1)"
                                        :disabled="noAnalyseHeaders"
                                    />

                                    <v-checkbox
                                        label="Tri"
                                        :ripple="false"
                                        class="pa-0 ma-0 mr-4"
                                        v-model="year1quarter"
                                        @click="showQuarter(1)"
                                        :disabled="noAnalyseHeaders"
                                    />

                                    <v-checkbox
                                        label="Sem"
                                        :ripple="false"
                                        class="pa-0 ma-0 mr-4"
                                        v-model="year1semester"
                                        @click="showSemester(1)"
                                        :disabled="noAnalyseHeaders"
                                    />

                                    <v-checkbox
                                        label="Ano"
                                        :ripple="false"
                                        class="pa-0 ma-0 mr-4"
                                        v-model="startingYear"
                                        @click="showYear(1)"
                                        :disabled="noAnalyseHeaders"
                                    />
                                </div>
                            </template>

                            <template v-else>
                                <span class="text-h6 mr-4"> {{ year1 ? year1.toString() : 'Ano 1' }} </span>

                                <div class="d-flex mt-1">
                                    <v-checkbox
                                        label="Mês"
                                        :ripple="false"
                                        class="pa-0 ma-0"
                                        v-model="year1month"
                                        @click="showAllMonths(1)"
                                        :disabled="noAnalyseHeaders || disableYear1"
                                    />

                                    <v-menu
                                        bottom
                                        left
                                        :close-on-content-click="false"
                                        style="z-index: 1000 !important;"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-btn
                                                icon
                                                v-on="on"
                                                x-small
                                                class="mr-4 pt-1"
                                                :disabled="noAnalyseHeaders || disableYear1"
                                            >
                                                <v-icon>mdi-dots-vertical</v-icon>
                                            </v-btn>
                                        </template>

                                        <v-sheet class="px-4 py-2" max-width="260px">
                                            <v-select
                                                v-model="year1months"
                                                :items="months"
                                                label="Escolha os meses"
                                                :item-text="item => item.text"
                                                @change="showMonth(1)"
                                                multiple
                                                return-object
                                            >
                                                <template v-slot:selection="{ item, index }">
                                                    <span v-if="index === 0">{{ item.text }}</span>

                                                    <span v-if="index === 1">{{ `, ${item.text}` }}</span>

                                                    <template v-if="index === 2">
                                                        <span>...</span>
                                                        <span class="grey--text text-caption pa-1">
                                                            {{ `+ ${year1months.length - 2}` }}
                                                        </span>
                                                    </template>

                                                </template>
                                            </v-select>

                                            <div class="d-flex justify-end">
                                                <v-btn small color="primary" class="ma-0 mb-2"
                                                    @click="year1month = true, showAllMonths(1)"
                                                    :disabled="year1months.length === 12"
                                                >
                                                    Selecionar todos
                                                </v-btn>
                                            </div>
                                        </v-sheet>
                                    </v-menu>

                                    <v-checkbox
                                        label="Tri"
                                        :ripple="false"
                                        class="pa-0 ma-0"
                                        v-model="year1quarter"
                                        @click="showAllQuarters(1)"
                                        :disabled="noAnalyseHeaders || disableYear1"
                                    />

                                    <v-menu
                                        bottom
                                        left
                                        :close-on-content-click="false"
                                        style="z-index: 1000 !important;"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-btn
                                                icon
                                                v-on="on"
                                                x-small
                                                class="mr-4 pt-1"
                                                :disabled="noAnalyseHeaders || disableYear1"
                                            >
                                                <v-icon>mdi-dots-vertical</v-icon>
                                            </v-btn>
                                        </template>

                                        <v-sheet class="px-4 py-2" max-width="240px">
                                            <v-select
                                                v-model="year1quarters"
                                                :items="quarters"
                                                label="Escolha os trimestres"
                                                :item-text="item => item.text"
                                                @change="showQuarter(1)"
                                                multiple
                                                return-object
                                            >
                                                <template v-slot:selection="{ item, index }">
                                                    <span v-if="index === 0">{{ item.text }}</span>

                                                    <span v-if="index === 1">{{ `, ${item.text}` }}</span>

                                                    <template v-if="index === 2">
                                                        <span>...</span>
                                                        <span class="grey--text text-caption pa-1">
                                                            {{ `+ ${year1quarters.length - 2}` }}
                                                        </span>
                                                    </template>

                                                </template>
                                            </v-select>

                                            <div class="d-flex justify-end">
                                                <v-btn small color="primary" class="ma-0 mb-2"
                                                    @click="year1quarter = true, showAllQuarters(1)"
                                                    :disabled="year1quarters.length === 4"
                                                >
                                                    Selecionar todos
                                                </v-btn>
                                            </div>
                                        </v-sheet>
                                    </v-menu>

                                    <v-checkbox
                                        label="Sem"
                                        :ripple="false"
                                        class="pa-0 ma-0"
                                        v-model="year1semester"
                                        @click="showAllSemesters(1)"
                                        :disabled="noAnalyseHeaders || disableYear1"
                                    />

                                    <v-menu
                                        bottom
                                        left
                                        :close-on-content-click="false"
                                        style="z-index: 1000 !important;"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-btn
                                                icon
                                                v-on="on"
                                                x-small
                                                class="mr-4 pt-1"
                                                :disabled="noAnalyseHeaders || disableYear1"
                                            >
                                                <v-icon>mdi-dots-vertical</v-icon>
                                            </v-btn>
                                        </template>

                                        <v-sheet class="px-4 py-2" max-width="240px">
                                            <v-select
                                                v-model="year1semesters"
                                                :items="semesters"
                                                label="Escolha os semestres"
                                                :item-text="item => item.text"
                                                @change="showSemester(1)"
                                                multiple
                                                return-object
                                            >
                                                <template v-slot:selection="{ item, index }">
                                                    <span v-if="index === 0">{{ item.text }}</span>

                                                    <span v-if="index === 1">{{ `, ${item.text}` }}</span>

                                                    <template v-if="index === 2">
                                                        <span>...</span>
                                                        <span class="grey--text text-caption pa-1">
                                                            {{ `+ ${year1semesters.length - 2}` }}
                                                        </span>
                                                    </template>

                                                </template>
                                            </v-select>

                                            <div class="d-flex justify-end">
                                                <v-btn small color="primary" class="ma-0 mb-2"
                                                    @click="year1semester = true, showAllSemesters(1)"
                                                    :disabled="year1semesters.length === 2"
                                                >
                                                    Selecionar todos
                                                </v-btn>
                                            </div>
                                        </v-sheet>
                                    </v-menu>

                                    <v-checkbox
                                        label="Ano 1"
                                        :ripple="false"
                                        class="pa-0 ma-0 mr-4"
                                        v-model="startingYear"
                                        @click="showYear(1)"
                                        :disabled="noAnalyseHeaders || disableYear1"
                                    />
                                </div>

                                <v-divider vertical class="mr-4 mb-4"></v-divider>

                                <span class="text-h6 mr-4"> {{ year2 ? year2.toString() : 'Ano 2' }} </span>
                                
                                <div class="d-flex mt-1">
                                    <v-checkbox
                                        label="Mês"
                                        :ripple="false"
                                        class="pa-0 ma-0"
                                        v-model="year2month"
                                        @click="showAllMonths(2)"
                                        :disabled="noAnalyseHeaders || disableYear2"
                                    />

                                    <v-menu
                                        bottom
                                        left
                                        :close-on-content-click="false"
                                        style="z-index: 1000 !important;"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-btn
                                                icon
                                                v-on="on"
                                                x-small
                                                class="mr-4 pt-1"
                                                :disabled="noAnalyseHeaders || disableYear2"
                                            >
                                                <v-icon>mdi-dots-vertical</v-icon>
                                            </v-btn>
                                        </template>

                                        <v-sheet class="px-4 py-2" max-width="240px">
                                            <v-select
                                                v-model="year2months"
                                                :items="months"
                                                :item-text="item => item.text"
                                                @change="showMonth(2)"
                                                label="Escolha os meses"
                                                multiple
                                                return-object
                                            >
                                                <template v-slot:selection="{ item, index }">
                                                    <span v-if="index === 0">{{ item.text }}</span>

                                                    <span v-if="index === 1">{{ `, ${item.text}` }}</span>

                                                    <template v-if="index === 2">
                                                        <span>...</span>
                                                        <span class="grey--text text-caption pa-1">
                                                            {{ `+ ${year2months.length - 2}` }}
                                                        </span>
                                                    </template>

                                                </template>
                                            </v-select>

                                            <div class="d-flex justify-end">
                                                <v-btn small color="primary" class="ma-0 mb-2"
                                                    @click="year2month = true, showAllMonths(2)"
                                                    :disabled="year2months.length === 12"
                                                >
                                                    Selecionar todos
                                                </v-btn>
                                            </div>
                                        </v-sheet>
                                    </v-menu>

                                    <v-checkbox
                                        label="Tri"
                                        :ripple="false"
                                        class="pa-0 ma-0"
                                        v-model="year2quarter"
                                        @click="showAllQuarters(2)"
                                        :disabled="noAnalyseHeaders || disableYear2"
                                    />

                                    <v-menu
                                        bottom
                                        left
                                        :close-on-content-click="false"
                                        style="z-index: 1000 !important;"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-btn
                                                icon
                                                v-on="on"
                                                x-small
                                                class="mr-4 pt-1"
                                                :disabled="noAnalyseHeaders || disableYear2"
                                            >
                                                <v-icon>mdi-dots-vertical</v-icon>
                                            </v-btn>
                                        </template>

                                        <v-sheet class="px-4 py-2" max-width="240px">
                                            <v-select
                                                v-model="year2quarters"
                                                :items="quarters"
                                                label="Escolha os trimestres"
                                                :item-text="item => item.text"
                                                @change="showQuarter(2)"
                                                multiple
                                                return-object
                                            >
                                                <template v-slot:selection="{ item, index }">
                                                    <span v-if="index === 0">{{ item.text }}</span>

                                                    <span v-if="index === 1">{{ `, ${item.text}` }}</span>

                                                    <template v-if="index === 2">
                                                        <span>...</span>
                                                        <span class="grey--text text-caption pa-1">
                                                            {{ `+ ${year2quarters.length - 2}` }}
                                                        </span>
                                                    </template>

                                                </template>
                                            </v-select>

                                            <div class="d-flex justify-end">
                                                <v-btn small color="primary" class="ma-0 mb-2"
                                                    @click="year2quarter = true, showAllQuarters(2)"
                                                    :disabled="year2quarters.length === 4"

                                                >
                                                    Selecionar todos
                                                </v-btn>
                                            </div>
                                        </v-sheet>
                                    </v-menu>

                                    <v-checkbox
                                        label="Sem"
                                        :ripple="false"
                                        class="pa-0 ma-0"
                                        v-model="year2semester"
                                        @click="showAllSemesters(2)"
                                        :disabled="noAnalyseHeaders || disableYear2"
                                    />

                                    <v-menu
                                        bottom
                                        left
                                        :close-on-content-click="false"
                                        style="z-index: 1000 !important;"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-btn
                                                icon
                                                v-on="on"
                                                x-small
                                                class="mr-4 pt-1"
                                                :disabled="noAnalyseHeaders || disableYear2"
                                            >
                                                <v-icon>mdi-dots-vertical</v-icon>
                                            </v-btn>
                                        </template>

                                        <v-sheet class="px-4 py-2" max-width="240px">
                                            <v-select
                                                v-model="year2semesters"
                                                :items="semesters"
                                                label="Escolha os semestres"
                                                :item-text="item => item.text"
                                                @change="showSemester(2)"
                                                multiple
                                                return-object
                                            >
                                                <template v-slot:selection="{ item, index }">
                                                    <span v-if="index === 0">{{ item.text }}</span>

                                                    <span v-if="index === 1">{{ `, ${item.text}` }}</span>

                                                    <template v-if="index === 2">
                                                        <span>...</span>
                                                        <span class="grey--text text-caption pa-1">
                                                            {{ `+ ${year2semesters.length - 2}` }}
                                                        </span>
                                                    </template>

                                                </template>
                                            </v-select>

                                            <div class="d-flex justify-end">
                                                <v-btn small color="primary" class="ma-0 mb-2"
                                                    @click="year2semester = true, showAllSemesters(2)"
                                                    :disabled="year2semesters.length === 2"
                                                >
                                                    Selecionar todos
                                                </v-btn>
                                            </div>
                                        </v-sheet>
                                    </v-menu>

                                    <v-checkbox
                                        label="Ano 2"
                                        :ripple="false"
                                        class="pa-0 ma-0 mr-4"
                                        v-model="laterYear"
                                        @click="showYear(2)"
                                        :disabled="noAnalyseHeaders || disableYear2"
                                    />
                                </div>
                            </template>

                            <v-divider vertical class="mr-4 mb-4"></v-divider>

                            <div class="d-flex mt-1">
                                <v-checkbox
                                    label="Subtotais"
                                    :ripple="false"
                                    class="pa-0 ma-0"
                                    v-model="subtotals"
                                    :disabled="noAnalyseHeaders"
                                />
                            </div>
                        </v-col>
                    </v-row>

                    <v-row no-gutters class="d-flex justify-start align-center mt-2">
                        <v-col class="d-flex justify-start">
                            <!-- <v-autocomplete
                                name="selection"
                                label="Visualização"
                                :items="[{id: 1, text: 'Tabela'}, {id: 2, text: 'Gráfico'}, {id: 3, text: 'Tabela + Gráfico'}]"
                                item-value="text"
                                return-object
                                @change="changeSelection($event)"
                                class="pa-0 mr-4 mb-2"
                                v-model="selection"
                                style="max-width: 300px !important; z-index: 1000 !important;"
                                hide-details
                            /> -->

                            <v-autocomplete
                                name="variation"
                                label="Variação"
                                :items = "variationItems"
                                item-value="text"
                                return-object
                                class="pa-0 mr-4 mb-2"
                                v-model="variation"
                                @change="changeVariation($event)"
                                style="max-width: 300px !important; z-index: 1000 !important;"
                                hide-details
                            />

                            <v-autocomplete v-if="selection.id === 2"
                                name="chartType"
                                label="Tipo do gráfico"
                                :items="chartTypes"
                                item-value="text"
                                return-object
                                class="pa-0 mr-4 mb-2"
                                v-model="chartType"
                                @change="changeChartType"
                                style="max-width: 300px !important; z-index: 1000 !important;"
                                hide-details
                            />

                            <v-select v-if="variation.id === 5"
                                label="Ano de referência"
                                v-model="yearReference"
                                :items="[{ id: 1, text: 'Ano 1' }, {id: 2, text: 'Ano 2' }]"
                                item-value="id"
                                item-text="text"
                                class="pa-0 mr-4 mb-2"
                                style="max-width: 150px !important; z-index: 1000 !important;"
                            ></v-select>

                            <div class="d-flex mt-1" v-if="variation.id >= 4">
                                <v-checkbox
                                    label="Variação (-)"
                                    :ripple="false"
                                    class="pa-0 ma-0 mr-4"
                                    v-model="absoluteVariation"
                                    @click="toggleAbsoluteVariation()"
                                    :disabled="noAnalyseHeaders"
                                />

                                <v-checkbox
                                    label="Variação (%)"
                                    :ripple="false"
                                    class="pa-0 ma-0 mr-4"
                                    v-model="percentageVariation"
                                    @click="togglePercentageVariation()"
                                    :disabled="noAnalyseHeaders"
                                />
                            </div>
                        </v-col>
                    </v-row>
                </v-tab-item>

                <v-tab-item>
                    <v-row no-gutters class="d-flex justify-start align-center pt-2">
                        <v-col v-for="(filter) in filters" :key="filter.name"
                            class="d-flex justify-start"
                            cols="2"
                        >
                            <v-autocomplete
                                v-model="filter.selectedItems"
                                :name="filter.name"
                                :label="filter.text"
                                :items="filter.items"
                                item-value="text"
                                return-object
                                class="pa-0 mr-4 mb-3"
                                @click="showFilterItems(filter)"
                                @change="setPayloadFilter(filter, $event)"
                                :loading="filterIsLoading(filter)"
                                clearable
                                multiple
                                style="z-index: 1000 !important;"
                                hide-details
                                :no-data-text="filterIsLoading(filter) ? 'Atualizando dados...' : 'Não há dados disponíveis'"
                            >
                                <template v-slot:selection="{ item, index }">
                                    <span v-if="index === 0" class="mr-2"
                                        style="display: inline-block; max-width: 250px !important; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                    >
                                        {{ item.text }}
                                    </span>
                                    <span v-if="index === 1" class="pt-1 text-caption primary--text">{{ `(+ ${filter.selectedItems.length - 1})`}}</span>
                                </template>
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                </v-tab-item>

                <v-tab-item>
                    <v-row no-gutters class="d-flex justify-start align-center pt-2">
                        <v-col v-for="displayFilter in displayFilters" :key="displayFilter.name"
                            class="d-flex justify-start"
                            cols="2"
                        >
                            <v-checkbox
                                :ripple="false"
                                v-model="displayFilter.visible"
                                :name="displayFilter.name"
                                :label="displayFilter.text"
                                @change="setPayloadDisplayFilter(displayFilter, $event)"
                                class="pa-0 ma-0"
                                hide-details
                            />
                        </v-col>

                    </v-row>

                    <v-row no-gutters class="pt-3 pb-1" v-if="selectedDisplayFilters.length">
                        <v-col class="d-flex align-center">
                            <v-chip v-for="displayFilter in selectedDisplayFilters" :key="displayFilter.name"
                                :draggable="true"
                                @dragstart="startDrag($event, displayFilter)"
                                @drop="onDrop($event, displayFilter)"
                                @dragover.prevent
                                @dragenter.prevent
                                class="grabbable mr-2"
                                small
                            >
                                {{displayFilter.text}}
                            </v-chip>
                            <!-- <span v-for="displayFilter in selectedDisplayFilters" :key="displayFilter.name"
                                class="text-caption mr-1"
                            >
                                {{ `${displayFilter.text} |` }}
                            </span> -->
                        </v-col>
                        <!-- <v-col cols="1" class="d-flex justify-end align-center">
                            <v-btn x-small text color="primary">
                                <v-icon x-small class="mr-1">
                                    mdi-autorenew
                                </v-icon>
                                Estado inicial
                            </v-btn>
                        </v-col> -->
                    </v-row>
                </v-tab-item>
            </v-tabs-items>
        </v-sheet>

        <v-sheet v-if="loading" :height="windowSize.y - 64 - 248" class="rounded-b">
            <div class="d-flex justify-center align-center" style="width: 100%; height: 100%;">
                <v-btn large text :loading="loading" color="primary">
                    Carregando
                </v-btn>
            </div>
        </v-sheet>

        <v-sheet v-else-if="!loading && noDataMessage" :height="windowSize.y - 64 - 248" class="rounded-b">
            <div class="d-flex flex-column justify-center align-center" style="width: 100%; height: 100%;">
                <span>{{ noDataMessage }}</span>
                <v-btn v-if="noDataBtn.text"
                    large text color="primary" class="mt-4"
                    @click="$router.push(noDataBtn.path)"
                >
                    <v-icon small class="mr-2">mdi-plus</v-icon>
                    {{ noDataBtn.text }}
                </v-btn>
            </div>
        </v-sheet>

        <v-sheet v-else-if="selection.id === 1" :height="showTab ? windowSize.y - 64 - 248 : windowSize.y - 64 - 102"
            class="rounded-b px-4 pt-2"
        >
            <HotTable ref="hotTable"
                licenseKey="1c81c-153e1-cb283-a4838-af03f"
                :data="data"
                :settings="settings"
                :columns="columns"
                :colHeaders="colHeaders"
                :rowHeaders="true"
                :hiddenColumns="hiddenColumns"
                :hiddenRows="hiddenRows"
                :contextMenu="contextMenu"
                :dropdownMenu="dropDownMenu"
                :nestedHeaders="nestedHeaders"
                :manualColumnResize="true"
                :multiColumnSorting="true"
                :bindRowsWithHeaders="true"
                :filters="true"
                width="100%"
                height="100%"
                :columnHeaderHeight="columnHeaderHeight"
                :rowHeaderWidth="50"
                :trimWhitespace="false"
                :copyPaste="true"
                :renderAllRows="false"
            />
        </v-sheet>

        <v-sheet v-else-if="selection.id === 2" :height="windowSize.y - 64 - 102"
            class="d-flex align-center justify-center rounded-b px-4 pt-2 pb-6"
        >
            <div ref="plotly"
                :style="plotlyStyles"
            ></div>
        </v-sheet>

        <CommentsMenu
            :drawer="drawer"
            @closeDrawer="drawer = false"
            :parentPayload="{ ...payload, selectedReport, selectedSlide }"
        />

        <ZnapStylesDialog v-if="dialogStyles"
            :dialogStyles="dialogStyles"
            :editedRowStyles="editedRowStyles"
            @setEditedRowStyle="setEditedRowStyle"
            @closeDialogStyles="dialogStyles = false"
            @saveEditedRowStyle="saveEditedRowStyle($event)"
        />
    </v-layout>
</template>

<script>
import lodash from 'lodash'
import { mapGetters } from 'vuex'
import { HotTable } from '@handsontable/vue'
import { registerLanguageDictionary, ptBR } from 'handsontable/i18n'
import numbro from 'numbro'
import languages from 'numbro/dist/languages.min'
import CommentsMenu from '@/components/znap/CommentsMenuReport.vue'
import ZnapStylesDialog from '@/components/znap/ZnapStylesDialog'
import Plotly from 'plotly.js-dist'

export default {
    name: 'Handsontable',

    components: {
        HotTable, CommentsMenu, ZnapStylesDialog
    },

    props: {
        options: { type: Array, required: true },
        // tableName: { type: String, required: true },
        // tableIcon: { type: String },
        // tableDescription: { type: String }
    },

    data() {
        return {
            //Tab
            tab: 0,
            tabItems: ['Evento', 'Filtros', 'Exibir'],
            showTab: true,
            variationItems: [
                {id: 1, text: 'Desabilitado'}, 
                // {id: 2, text: 'Diferença'},
                // {id: 3, text: 'Percentual'},
                {id: 4, text: 'Análise Horizontal - Ano'}, // HORIZONTAL_ANALYSIS_PREV_YEAR
                {id: 5, text: 'Análise Horizontal por evento'}, // HORIZONTAL_ANALYSIS_EVENT
                {id: 6, text: 'Análise Horizontal YTD por evento'},
                // {id: 7, text: 'Analise Horizontal - Mês'} - HORIZONTAL_ANALYSIS_PREV_MONTH
            ],
            dialogStyles: false,
            queryType: "BI_YEAR",
            yearReference: null,

            // Hot Table
            data: [],
            settings: {
                cells: (row, col) => {
                    var cellProperties = {}

                    cellProperties.readOnly = true

                    if (this.rowsStyle[row].css?.includes('first-column-only')) {
                        if (col === 0) {
                            cellProperties.className = this.rowsStyle[row].css
                        } else {
                            let arrayOfStyles = this.rowsStyle[row].css.split(' ')
                            let borderStyles = arrayOfStyles.filter(s => s.includes('border')).join(' ')
                            cellProperties.className = borderStyles
                        }
                    } else {
                        cellProperties.className = this.rowsStyle[row].css
                    }

                    if (this.isTotalDateColumn(this.apiResult.rows[0][col])) {
                        cellProperties.className += ` border-sides-1 font-bold`
                    }

                    if (this.rowsStyle[row].type === 'percentage') {
                        if (this.apiResult.rows[0][col] !== 'Ano Fiscal') {
                            cellProperties.numericFormat = {
                                pattern: {
                                    output: "percent",
                                    negative: "parenthesis",
                                    mantissa: 2,
                                },
                                culture: ptBR.languageCode
                            }
                        }
                    }

                    return cellProperties
                },
                beforeCopy: (data) => {
                    let stringData = data.map(d => d.map(c => String(c).replace('.',',')))
                    let excelData = stringData.map(lines => lines.join('\t')).join("\t\r\n")

                    document.addEventListener('copy', function(e) {
                        e.clipboardData.setData('text/plain', excelData);
                        e.preventDefault();
                    })

                    this.$toast.success('Valor copiado para a área de transferência')
                    return false
                },
                language: ptBR.languageCode,
                fillHandle: {
                    autoInserRow: false
                },
            },

            analyseHeaders: [],
            apiResult: {
                headers: [],
                rows: [],
                columns: [],
            },

            filterConditions: {},
            filters: [],
            displayFilters: [],
            selectedDisplayFilters: [],
            loadingFilters: [],
            subtotals: false,
            selection: {id: 1, text: 'Tabela'},
            variation: {id: 1, text: 'Desabilitado'},
            percentageVariation: true,
            absoluteVariation: true,

            loadingEvents: false,
            events: [],
            event1: null,
            event2: null,
            multipleEvents: true,
            calculateDifference: false,
            chartType: {id: 'bar', text: 'Barras'},
            chartTypes: [
                {id: 'bar', text: 'Barras'},
                {id: 'bubble', text: 'Bolhas'},
                {id: 'box', text: 'Box plot'},
                {id: 'waterfall', text: 'Cascata'},
                {id: 'scatter', text: 'Dispersão'},
                {id: 'funnel', text: 'Funíl'},
                {id: 'histogram', text: 'Histograma'},
                {id: 'heatmap', text: 'Mapa de calor'},
                {id: 'pie', text: 'Pizza'},
            ],

            columns: [],
            hiddenColumns: { columns: [], copyPasteEnabled: false },
            hiddenRows: { rows: [], indicators: true },
            colHeaders: [],
            nestedHeaders: [],
            firstNestedColspan: 0,
            columnHeaderHeight: [],

            contextMenu: [
                'copy',
                'hidden_rows_hide',
                'hidden_rows_show',
                'hidden_columns_hide',
                'hidden_columns_show',
                {
                    name() {
                        return 'Estilizar linha'
                    },
                    hidden() {
                        let hidden = true
                        const cellCoords = this.getSelectedLast()
                        if (cellCoords[1] === -1) {
                            hidden = false
                        }
                        return hidden
                    },
                    callback: () => {
                        this.dialogStyles = true
                        let row = this.hotInstance.getSelectedLast()[2]
                        let index = this.apiResult.columns.findIndex(c => c.columnAlias === 'id_account_group')
                        let id = this.data[row][index]
                        let css = this.rowsStyle[row].css

                        this.editedRowStyles = { id, css, row }
                    }
                }
            ],
           
            dropDownMenu: [
                'filter_by_condition',
                'filter_by_condition2',
                'filter_action_bar',
                'filter_operators',
                'filter_by_value'
            ],


            // Options
            payload: {
                id_event: [],
            },

            year1: null,
            year2: null,

            // Date filters
            months: [],
            quarters: [],
            semesters: [],
            startingYear: true,
            startingMonthIndex: null,
            year1month: true,
            year1months: [],
            year1quarter: true,
            year1quarters: [],
            year1semester: true,
            year1semesters: [],
            laterYear: true,
            year2month: true,
            year2months: [],
            year2quarter: true,
            year2quarters: [],
            year2semester: true,
            year2semesters: [],
            yearMonthInputStart: null,
            fiscalYearStartMonth: null,
            currentMonth: null,

            // Unsaved changes
            dialogCloseGrid: false,
            dialogRefreshGrid: false,
            dialogSelectEvent: false,
            lastRefreshParams: null,

            windowSize: { x: 0, y: 0 },
            loading: true,
            saving: false,
            noDataMessage: null,
            noDataBtn: { path: null, text: null },

            hotInstance: null,

            drawer: false,
            exporting: false,
            importing: false,

            rowsStyle: [],
            editedRowStyles: null,

            // save report dialog
            saveDialog: false,
            step: "1",
            report: {
                id: null,
                dynamic_report: "",
                number: null,
                title: null,
                text: null,
                format: null,
                pivotTable: {}
            },
            reports: [],
            verifiedReportDescription: 0,
            users: [],
            admins: [],
            selectAdmin: [],
            selectUser: [],
            selectAdminUnchanged: [],
            selectUserUnchanged: [],
            adminUserGroup: null,
            userGroup: null,
            check: true,
            savingReport: false,

            // import report dialog
            importDialog: false,
            gettingReports: false,
            selectedReport: null,
            gettingSlides: false,
            slides: [],
            selectedSlide: null,
            importedReport: false,

            // save slide dialog
            saveSlideSelectedReport: null,
            saveSlideDialog: false,
            reportNewSlide: {
                number: null,
                title: null,
                text: null,
                format: null,
                pivotTable: {}
            },

            // copy slide dialog
            copySlideDialog: false,
            copySlideSelectedReport: null,
        }
    },

    async mounted() {
        registerLanguageDictionary(ptBR)
        numbro.registerLanguage(languages['pt-BR'])
        this.addPreventCloseHandler()
        this.getReports()

        if (this.$route.query.report) {
            this.loading = true
            this.selectedReport = parseInt(this.$route.query.report, 10)
            this.selectedSlide = parseInt(this.$route.query.slide, 10)
            
            try {
                let res = await this.$http.post(this.$ipDynamicReport + `dynamic-report-slide/list/${this.selectedSlide}`, {})
                if (res) {
                    let slide = res.data.rows[0]
                    this.importReport(slide)
                    window.history.replaceState({}, document.title, "" + "/sales-report")
                }
            } catch (err) {
                this.$fnError(err)
            }
        }
    },

    updated() {
        if (this.$refs.hotTable) {
            if (this.hotInstance === null) {
                this.hotInstance = this.$refs.hotTable.hotInstance
                this.hotInstance.render()
            }
        }
    },
        
    destroyed() {
        this.removePreventCloseHandler()
    },

    watch: {
        report: {
            deep: true,
            async handler(value) {
                let payload = {
                    filter: {
                        conditions: [
                            {
                                AndOr: "AND",
                                column: "dynamic_report",
                                operator: "=",
                                value: `${value.dynamic_report}`
                            }
                        ]
                    }
                }

                try {
                    const res = await this.$http.post(this.$ipDynamicReport + 'dynamic-report/list-options', payload)
                    if (res) {
                        if (res.data.rows.length > 0) {
                            return this.verifiedReportDescription = -1
                        } else {
                            return this.verifiedReportDescription = 1
                        }
                    }
                } catch (err) {
                    this.$fnError(err)
                }
            }
        },
    },

    computed: {
        ...mapGetters('auth', ['getUser', 'getMenu']),

        tableName() {
            const tableName = this.getTableInfo().tableName

            return tableName ? tableName : 'Erro ao listar a tabela'
        },

        tableIcon() {
            const tableIcon = this.getTableInfo().tableIcon

            return tableIcon ? tableIcon : 'mdi-view-list'
        },

        eventItems() {
            let eventItems
            let eventOptions = this.options.find(option => option.column === 'id_event').items

            if (this.events.length) {
                eventItems = eventOptions.filter(e => e.id_account_group_version === this.events[0].id_account_group_version)
            } else if (this.event1) {
                eventItems = eventOptions.filter(e => e.id_account_group_version === this.event1.id_account_group_version)
            } else {
                eventItems = eventOptions
            }

            return eventItems
        },

        noEventSelected() {
            let noEventSelected = true

            if (this.variation.id === 2 || this.variation.id === 3 || this.variation.id === 5 || this.variation.id === 6) {
                if (this.event1 && this.event2) {
                    if (this.event1 != this.event2) {
                        noEventSelected = false
                    }
                }
            } else {
                if (this.events.length) {
                    noEventSelected = false
                }
            }

            return noEventSelected 
        },

        noDisplayFilterSelected() {
            return !!this.displayFilters.length && !this.selectedDisplayFilters.length
        },

        noYearReferenceSelected() {
            return this.variation.id === 5 && !this.yearReference
        },

        disableYear1() {
            return this.variation.id === 5 && this.yearReference === 2
        },

        disableYear2() {
            return this.variation.id === 5 && this.yearReference === 1
        },

        noReportsFound() {
            return this.reports.length ? false : true
        },

        noReportSelected() {
            return !this.selectedReport ? true : false
        },

        noAnalyseHeaders() {
            return this.analyseHeaders.length ? false : true
        },

        disableActionButtons() {
            let disableActionButtons = false

            let eventOptions = this.options.find(option => option.column === 'id_event').items
            if (!eventOptions.length) {
                disableActionButtons = true
            }

            return disableActionButtons
        },

        unsavedChanges() {
            return false
        },

        plotlyStyles() {
            return {
                'width': `300px`,
                'height': `200px`
            }
        },

        reportDescriptionHint() {
            let hint = ''

            if (this.verifiedReportDescription !== 0 && this.report.dynamic_report.length > 0) {
                if (this.verifiedReportDescription === -1) {
                    hint = "Esta descrição já está em uso."
                } else {
                    hint = "Tudo certo!"
                }
            } else {
                hint = ''
            }

            return hint
        },

        disabledVerifyReportDescriptionButton() {
            return this.report.dynamic_report.length === 0
        },

        disableSaveReportButton() {
            let disabled = false

            if ((this.step === '1' || this.step === 1) && (!this.report.dynamic_report || this.verifiedReportDescription !== 1)) {
                disabled = true
            }

            if ((this.step === '2' || this.step === 2) && (!this.report.title)) {
                disabled = true
            }

            return disabled
        },

        disableSaveSlideButton() {
            let disabled = false

            if (!this.reportNewSlide.number || !this.reportNewSlide.title) {
                disabled = true
            }

            return disabled
        },

        selectAllUsersIcon() {
            let icon = ''

            if (!this.selectUser.length) {
                icon = 'mdi-checkbox-blank-outline'
            } else if (this.selectUser.length < this.users.length) {
                icon = 'mdi-minus-box'
            } else {
                icon = 'mdi-close-box'
            }

            return icon
        }
    },

    beforeMount() {
        this.initializePage()
    },

    methods: {
        startDrag(event, displayFilter) {
            event.dataTransfer.dropEffect = 'move'
            event.dataTransfer.effectAllowed = 'move'
            event.dataTransfer.setData('displayFilterName', displayFilter.name)
        },

        onDrop(event, displayFilter) {
            let selectedDisplayFilterName = event.dataTransfer.getData('displayFilterName')
            let selectedAnalyseHeader = this.analyseHeaders.find(h => h.name == selectedDisplayFilterName)
            let selectedAnalyseHeaderIndex = this.analyseHeaders.findIndex(h => h.name == selectedDisplayFilterName)

            let selectedDisplayFilter = this.selectedDisplayFilters.find(f => f.name == selectedDisplayFilterName)
            let selectedDisplayFilterIndex = this.selectedDisplayFilters.findIndex(f => f.name == selectedDisplayFilterName)

            let dropDisplayFilter = displayFilter
            let dropDisplayFilterIndex = this.selectedDisplayFilters.findIndex(f => f.name == dropDisplayFilter.name)
            let dropAnalyseHeaderIndex = this.analyseHeaders.findIndex(f => f.name == dropDisplayFilter.name)

            this.analyseHeaders.splice(selectedAnalyseHeaderIndex, 1)
            this.analyseHeaders.splice(dropAnalyseHeaderIndex, 0, selectedAnalyseHeader)

            this.selectedDisplayFilters.splice(selectedDisplayFilterIndex, 1)
            this.selectedDisplayFilters.splice(dropDisplayFilterIndex, 0, selectedDisplayFilter)
        },

        getTableInfo() {
            let tableInfo = {
                tableName: '',
                tableIcon: '',
            }

            for (let i = 0; i < this.getMenu.length; i++) {
                let submenu = this.getMenu[i].menus.find(m => m.frontend_route_path === this.$route.path)
                if (submenu) {
                    tableInfo.tableIcon = this.getMenu[i].icon
                    tableInfo.tableName = submenu.label
                }
            }

            return tableInfo
        },

        async initializePage() {
            this.loadingEvents = true

            try {
                let filter = {
                    orderColumn: 'description',
                    textColumn: 'description',
                    conditions: [
                        {
                            AndOr: "AND",
                            column: "id_module",
                            operator: "=",
                            value: 5
                        },
                        {
                            AndOr: "AND",
                            column: "id_planning_function",
                            operator: "IN",
                            not: true,
                            value: [3, 14] // Mapa de alocação e faturamento
                        },
                    ]
                }

                const eventOption = this.options.find(option => option.column === 'id_event')
                const eventRes = await this.$http.post(eventOption.endpoint[0] + eventOption.endpoint[1] + '/list-options', { filter })
                if (eventRes.data.rows.length) {
                    eventOption.items = eventRes.data.rows

                    this.noDataMessage = "Selecione um evento para exibir o relatório"

                    this.getReports()

                    if (!this.$route.query.report) {
                        this.loading = false
                    }

                    this.loadingEvents = false
                } else {
                    return this.noEventsFound()
                }
            } catch (err) {
                this.$fnError(err)
            }
        },

        createPlotly() {
            let plotly = this.$refs.plotly
            let type = this.chartType ? this.chartType.id : 'bar'
            let data = null
            let layout = null

            if (type === 'bar') {
                let chartProps = this.setBarChart(type)
                data = chartProps.data
                layout = chartProps.layout
            } else if (type === 'scatter') {
                let chartProps = this.setScatterChart(type)
                data = chartProps.data
                layout = chartProps.layout
            } else if (type === 'pie') {
                let chartProps = this.setPieChart(type)
                data = chartProps.data
                layout = chartProps.layout
            } else if (type === 'bubble') {
                let chartProps = this.setBubbleChart()
                data = chartProps.data
                layout = chartProps.layout
            } else if (type === 'histogram') {
                let chartProps = this.setHistogramChart(type)
                data = chartProps.data
                layout = chartProps.layout
            } else if (type === 'heatmap') {
                let chartProps = this.setHeatmapChart(type)
                data = chartProps.data
                layout = chartProps.layout
            } else if (type === 'box') {
                let chartProps = this.setBoxChart(type)
                data = chartProps.data
                layout = chartProps.layout
            } else if (type === 'funnel') {
                let chartProps = this.setFunnelChart(type)
                data = chartProps.data
                layout = chartProps.layout
            } else if (type === 'waterfall') {
                let chartProps = this.setWaterfallChart(type)
                data = chartProps.data
                layout = chartProps.layout
            }

            let config = {
                // margin: { t: 0 },
                displayModeBar: false,
                responsive: true
            }

            Plotly.newPlot(
                plotly,
                data,
                layout,
                config
            )
        },

        setBarChart(type) {
            let trace1 = {
                x: ['Mês 03 Ano 1', 'Mês 04 Ano 1', 'Mês 05 Ano 1', 'Tri 01 Ano 1', 'Mês 06 Ano 1', 'Mês 07 Ano 1', 'Mês 08 Ano 1', 'Tri 02 Ano 1', 'Sem 01 Ano 1', 'Mês 09 Ano 1', 'Mês 10 Ano 1', 'Mês 11 Ano 1', 'Tri 03 Ano 1', 'Mês 12 Ano 1', 'Mês 01 Ano 1', 'Mês 02 Ano 1', 'Tri 04 Ano 1', 'Sem 02 Ano 1', 'Total Ano Ano 1'],
                y: [3511, 3160, 3377, 10048, 3846, 3850, 3231, 10927, 20975, 3472, 3586, 3534, 10592, 3454, 3521, 3954, 10929, 21521, 42496],
                type,
                name: 'Vendas'
            }

            let trace2 = {
                x: ['Mês 03 Ano 1', 'Mês 04 Ano 1', 'Mês 05 Ano 1', 'Tri 01 Ano 1', 'Mês 06 Ano 1', 'Mês 07 Ano 1', 'Mês 08 Ano 1', 'Tri 02 Ano 1', 'Sem 01 Ano 1', 'Mês 09 Ano 1', 'Mês 10 Ano 1', 'Mês 11 Ano 1', 'Tri 03 Ano 1', 'Mês 12 Ano 1', 'Mês 01 Ano 1', 'Mês 02 Ano 1', 'Tri 04 Ano 1', 'Sem 02 Ano 1', 'Total Ano Ano 1'],
                y: [3041, 3648, 3525, 10214, 3442, 3660, 2830, 9932, 20146, 3084, 3073, 2945, 9102, 3449, 3336, 3663, 10448, 19550, 39696],
                type,
                name: 'Vendas de mercadorias'
            }

            let trace3 = {
                x: ['Mês 03 Ano 1', 'Mês 04 Ano 1', 'Mês 05 Ano 1', 'Tri 01 Ano 1', 'Mês 06 Ano 1', 'Mês 07 Ano 1', 'Mês 08 Ano 1', 'Tri 02 Ano 1', 'Sem 01 Ano 1', 'Mês 09 Ano 1', 'Mês 10 Ano 1', 'Mês 11 Ano 1', 'Tri 03 Ano 1', 'Mês 12 Ano 1', 'Mês 01 Ano 1', 'Mês 02 Ano 1', 'Tri 04 Ano 1', 'Sem 02 Ano 1', 'Total Ano Ano 1'],
                y: [3319, 3496, 3765, 10580, 4075, 3392, 3403, 10870, 21450, 3751, 3304, 3490, 10545, 3281, 3876, 3161, 10318, 20863, 42313],
                type,
                name: 'Vendas de serviços'
            }

            let data = [trace1, trace2, trace3]
            let layout = {
                title: 'Título do gráfico',
                showlegend: true,
                font: { size: 16 },
                barmode: 'group', // 'stack'
                // xaxis: { tickangle: -45 },
            }

            return { data, layout }
        },

        setScatterChart(type) {
            let trace1 = {
                x: ['Mês 03 Ano 1', 'Mês 04 Ano 1', 'Mês 05 Ano 1', 'Tri 01 Ano 1', 'Mês 06 Ano 1', 'Mês 07 Ano 1', 'Mês 08 Ano 1', 'Tri 02 Ano 1', 'Sem 01 Ano 1', 'Mês 09 Ano 1', 'Mês 10 Ano 1', 'Mês 11 Ano 1', 'Tri 03 Ano 1', 'Mês 12 Ano 1', 'Mês 01 Ano 1', 'Mês 02 Ano 1', 'Tri 04 Ano 1', 'Sem 02 Ano 1', 'Total Ano Ano 1'],
                y: [3511, 3160, 3377, 10048, 3846, 3850, 3231, 10927, 20975, 3472, 3586, 3534, 10592, 3454, 3521, 3954, 10929, 21521, 42496],
                type,
                name: 'Vendas'
            }

            let trace2 = {
                x: ['Mês 03 Ano 1', 'Mês 04 Ano 1', 'Mês 05 Ano 1', 'Tri 01 Ano 1', 'Mês 06 Ano 1', 'Mês 07 Ano 1', 'Mês 08 Ano 1', 'Tri 02 Ano 1', 'Sem 01 Ano 1', 'Mês 09 Ano 1', 'Mês 10 Ano 1', 'Mês 11 Ano 1', 'Tri 03 Ano 1', 'Mês 12 Ano 1', 'Mês 01 Ano 1', 'Mês 02 Ano 1', 'Tri 04 Ano 1', 'Sem 02 Ano 1', 'Total Ano Ano 1'],
                y: [3041, 3648, 3525, 10214, 3442, 3660, 2830, 9932, 20146, 3084, 3073, 2945, 9102, 3449, 3336, 3663, 10448, 19550, 39696],
                type,
                name: 'Vendas de mercadorias'
            }

            let trace3 = {
                x: ['Mês 03 Ano 1', 'Mês 04 Ano 1', 'Mês 05 Ano 1', 'Tri 01 Ano 1', 'Mês 06 Ano 1', 'Mês 07 Ano 1', 'Mês 08 Ano 1', 'Tri 02 Ano 1', 'Sem 01 Ano 1', 'Mês 09 Ano 1', 'Mês 10 Ano 1', 'Mês 11 Ano 1', 'Tri 03 Ano 1', 'Mês 12 Ano 1', 'Mês 01 Ano 1', 'Mês 02 Ano 1', 'Tri 04 Ano 1', 'Sem 02 Ano 1', 'Total Ano Ano 1'],
                y: [3319, 3496, 3765, 10580, 4075, 3392, 3403, 10870, 21450, 3751, 3304, 3490, 10545, 3281, 3876, 3161, 10318, 20863, 42313],
                type,
                name: 'Vendas de serviços'
            }

            let data = [trace1, trace2, trace3]
            let layout = {
                title: 'Título do gráfico',
                showlegend: true,
                font: { size: 16 }
            }

            return { data, layout }
        },

        setPieChart(type) {
            let trace1 = {
                values: [19, 26, 55],
                labels: ['Residential', 'Non-Residential', 'Utility'],
                type
            }
    
            let data = [trace1]
            
            let layout = {
                title: 'Título do gráfico',
                showlegend: true,
                font: { size: 16 }
            }

            return {data, layout}
        },

        setBubbleChart() {
            let trace1 = {
                x: [1, 2, 3, 4],
                y: [10, 11, 12, 13],
                mode: 'markers',
                marker: {
                    size: [40, 60, 80, 100]
                }
            }

            let data = [trace1]

            let layout = {
                title: 'Título do gráfico',
                showlegend: true,
            }

            return {data, layout}
        },

        setHistogramChart(type) {
            let x = []

            for (let i = 0; i < 500; i ++) {
                x[i] = Math.random()
            }

            let trace = {
                x,
                type
            }

            let data = [trace]
            let layout = null

            return {data, layout}
        },

        setHeatmapChart(type) {
            let data = [
                {
                    z: [[1, 20, 30], [20, 1, 60], [30, 60, 1]],
                    type
                }
            ]

            let layout = null

            return {data, layout}
        },

        setBoxChart(type) {
            let y0 = []
            let y1 = []

            for (let i = 0; i < 50; i ++) {
                y0[i] = Math.random()
                y1[i] = Math.random() + 1
            }

            let trace1 = {
                y: y0,
                type
            }

            let trace2 = {
                y: y1,
                type
            }

            let data = [trace1, trace2]
            let layout = null

            return {data, layout}
        },

        setFunnelChart(type) {
            let data = [{
                type,
                y: ["Website visit", "Downloads", "Potential customers", "Invoice sent", "Closed delas"],
                x: [13873, 10533, 5443, 2703, 908], hoverinfo: 'x+percent previous+percent initial'
            }]

            let layout = null

            return {data, layout}
        },

        setWaterfallChart(type) {
            let data = [{
                name: "2018",
                type,
                orientation: "v",
                measure: [
                    "relative",
                    "relative",
                    "total",
                    "relative",
                    "relative",
                    "total"
                ],
                x: [
                    "Vendas",
                    "Consultória",
                    "Receita líquida",
                    "Compras",
                    "Outras despesas",
                    "Lucro antes dos impostos"
                ],
                textposition: "outside",
                text: [
                    "+60",
                    "+80",
                    "",
                    "-40",
                    "-20",
                    "Total"
                ],          
                y: [
                    60,
                    80,
                    0,
                    -40,
                    -20,
                    0
                ],
                connector: {
                    line: {
                        color: "rgb(63, 63, 63)"
                    }
                },
            }]

            let layout = {
                title: {
                    text: "Lucros e Perdas - 2018"
                },
                xaxis: {
                    type: "category"
                },
                yaxis: {
                    type: "linear"
                },
                autosize: true,
                showlegend: true
            }

            return {data, layout}
        },

        changeChartType() {
            this.createPlotly()
        },

        changeSelection(e) {
            if (e.id === 1) {
                this.loading = true
                let plotly = this.$refs.plotly
                Plotly.purge(plotly)
                this.loadGrid()
            } else if(e.id === 2) {
                this.hotInstance = null
                this.$nextTick(() => {
                    this.createPlotly()
                })
            }
        },

        changeVariation(e) {
            this.queryType = 'BI_YEAR'

            if (e.id === 1) {
                this.multipleEvents = true
                this.calculateDifference = false
                this.event1 = null
                this.event2 = null
            } else if (e.id === 2) {
                this.multipleEvents = false
                this.calculateDifference = '-'
            } else if (e.id === 3) {
                this.multipleEvents = false
                this.calculateDifference = '%'
            } else if (e.id === 4) {
                this.multipleEvents = true
                this.calculateDifference = false
                this.queryType = 'HORIZONTAL_ANALYSIS_PREV_YEAR'
                this.event1 = null
                this.event2 = null
            } else if (e.id === 5) {
                this.multipleEvents = false
                this.calculateDifference = false
                this.queryType = 'HORIZONTAL_ANALYSIS_EVENT'
                this.events = []
            } else if (e.id === 6) {
                this.multipleEvents = false
                this.calculateDifference = false
                this.queryType = 'HORIZONTAL_ANALYSIS_EVENT_TO_DATE'
                this.events = []
            }
        },

        setEditedRowStyle(e) {
            let index = this.editedRowStyles.row

            let newStyle = {
                rowId: index,
                css: e || ''
            }

            this.rowsStyle.splice(index, 1, newStyle)
            this.hotInstance.render()
            
            this.editedRowStyles = null
        },

        async saveEditedRowStyle(payload) {
            try {
                const res = await this.$http.put('https://api.account.znaptech.com/account-group/edit-css', { ...payload })
                if (res) {
                    this.setEditedRowStyle(payload.items[0].css)
                    this.dialogStyles = false
                    this.saving = false
                }
            } catch (err) {
                this.$fnError(err)
                this.saving = false
            }

            this.dialogStyles = false
        },

        setSelectedEvents(e) {
            this.events = e
            this.payload.id_event = []

            e.forEach((event) => {
                this.payload.id_event.push(event.id)
            })
        },

        verifySelectedEvent(e) {
            if (e) {
                if (this.event1 && this.event2) {
                    if (this.event1 === this.event2) {
                        this.$toast.error('Os eventos selecionados não podem ser iguais')
                    }
                }
            }
        },

        noEventsFound() {
            this.event = -1
            this.payload.id_event = null

            this.noDataMessage = "Nenhum evento encontrado"
            this.noDataBtn = {
                path: '/event',
                text: 'Cadastrar evento'
            }

            this.loading = false
            this.loadingEvents = false
        },

        refreshGrid() {
            this.loading = true

            let sameEvents = false
            let sameVariation = false
            let sameYearReference = false
            let idAccountGroupVersion = null

            if (this.multipleEvents) {
                idAccountGroupVersion = this.events[0].id_account_group_version
            } else {
                idAccountGroupVersion = this.event1.id_account_group_version
            }

            if (this.lastRefreshParams) {
                if (this.multipleEvents) {
                    sameEvents = lodash.isEqual(this.lastRefreshParams.events, this.events)
                } else {
                    sameEvents = (this.lastRefreshParams.event1 === this.event1 && this.lastRefreshParams.event2 === this.event2)
                }

                sameVariation = this.lastRefreshParams.variation.id === this.variation.id
                sameYearReference = this.lastRefreshParams.yearRerence === this.yearReference

                this.importedReport = this.lastRefreshParams.idAccountGroupVersion === idAccountGroupVersion && sameVariation && sameYearReference
            }

            if (!this.importedReport) {
                this.selectedSlide = null
                this.report = {
                    id: null,
                    dynamic_report: "",
                    number: null,
                    title: null,
                    text: null,
                    format: null,
                    pivotTable: {}
                }
            }

            if ((sameEvents && sameVariation && sameYearReference) || this.importedReport) {
                this.loadGrid()
            } else {
                if (this.lastRefreshParams) {
                    if (this.lastRefreshParams.idAccountGroupVersion !== idAccountGroupVersion) {
                        this.clearFilters()
                    }
                }

                this.getAnalyseHeaders()
            }

            this.lastRefreshParams = {
                events: this.events,
                variation: this.variation,
                event1: this.event1,
                event2: this.event2,
                filterConditions: this.filterConditions,
                idAccountGroupVersion,
                yearRerence: this.yearReference
            }
        },

        clearFilters() {
            this.filterConditions = {}
            this.filters = []
            this.displayFilters = []
            this.selectedDisplayFilters = []
            this.loadingFilters = []
        },

        clearEvent1() {
            this.event2 = null
            
            if (this.multipleEvents) {
                this.event1 = null
                this.event2 = null
            } else {
                this.events = []
            }
        },

        async getAnalyseHeaders() {
            let payload = { 
                queryType: this.queryType
            }

            payload['yearReference'] = this.setYearReference(this.variation.id)

            let endpoint = this.setEndpoint()

            try {
                const salesPlanningHeadersRes = await this.$http.post(`${this.$ipSalesPlanning}${endpoint}/analyse-headers`, { ...payload })
                if (salesPlanningHeadersRes) {
                    this.analyseHeaders = salesPlanningHeadersRes.data.headers

                    this.setDateColumns()
                    this.loadGrid(true)
                }
            } catch (err) {
                this.$fnError(err)
            }
        },

        setEndpoint() {
            let idPlanningFunction = null
            if (this.variation.id === 1 || this.variation.id === 4) {
                idPlanningFunction = this.events[0].id_planning_function
            } else {
                idPlanningFunction = this.event1.id_planning_function
            }

            if (idPlanningFunction === 1) {
                return 'sales-planning'
            } else if (idPlanningFunction === 2) {
                return 'sales-planning-customer'
            } else {
                return 'employee-allocation-planning'
            }
        },

        async loadGrid(newAnalyseHeaders = false) {
            this.noDataMessage = null
            this.noDataBtn = { path: null, text: null }
            this.data = []
            this.columns = []
            this.apiResult = { headers: [], rows: [], columns: [] }
            
            let plotly = this.$refs.plotly
            if (plotly) {
                Plotly.purge(plotly)
            }

            this.hotInstance = null

            let filterConditions = { filter: { conditions: [] }}
            for (let filter in this.filterConditions) {
                if (this.filterConditions[filter].length) {
                    filterConditions.filter.conditions.push({
                        AndOr: "AND",
                        column: filter,
                        operator: "IN",
                        value: this.filterConditions[filter]
                    })
                }
            }

            if (newAnalyseHeaders) {
                let foundAccount = this.analyseHeaders.find(a => a.name === 'account_group_description')
                if (foundAccount?.visible) {
                    let foundIndex = this.analyseHeaders.findIndex(a => a.name === 'account_group_description')
                    this.analyseHeaders.splice(foundIndex, 1)
                    this.analyseHeaders.unshift(foundAccount)
                }

                this.setFilters()
            }

            this.setFirstNestedColspan()

            let accountGroupIndex = this.analyseHeaders.findIndex(a => a.name === 'id_account_group')
            if (accountGroupIndex > -1) this.analyseHeaders[accountGroupIndex].visible = true

            if (this.variation.id === 2 || this.variation.id === 3) {
                let eventIndex = this.analyseHeaders.findIndex(a => a.name === 'event')
                this.analyseHeaders[eventIndex].visible = true

                let idEventIndex = this.analyseHeaders.findIndex(a => a.name === 'id_event')
                this.analyseHeaders[idEventIndex].visible = true
            }

            let events = null
            if (this.multipleEvents) {
                events = this.payload.id_event
            } else {
                events = [this.event1.id, this.event2.id]
                this.events = []
                this.events.push(this.event1, this.event2)
            }

            this.payload.id_account_group_version = this.events[0].id_account_group_version
            this.year1 = this.events[0].fiscal_year
            this.yearMonthInputStart = this.events[0].year_month_input_start
            this.fiscalYearStartMonth = this.events[0].fiscal_year_start_month
            this.startingMonthIndex = this.events[0].fiscal_year_start_month - 1
            this.year2 = this.year1 + 1

            let payload = {
                NoFormulas: true,
                calculateDifference: this.calculateDifference,
                ResultInArray: true,
                queryType: this.queryType,
                subtotals: this.subtotals,
                columns: this.analyseHeaders,
                id_account_group_version: this.payload.id_account_group_version,
                events,
                ...filterConditions
            }

            if (this.variation.id === 6) {
                this.currentMonth = new Date().getMonth()
                payload['monthReference'] = this.currentMonth
            }

            payload['yearReference'] = this.setYearReference(this.variation.id)

            let endpoint = this.setEndpoint()

            try {
                const salesPlanningRes = await this.$http.post(`${this.$ipSalesPlanning}${endpoint}/analyse`, { ...payload })
                if (salesPlanningRes) {
                    this.apiResult.headers = salesPlanningRes.data.headers
                    this.apiResult.columns = salesPlanningRes.data.columns
                    this.apiResult.rows = salesPlanningRes.data.rows

                    if (this.apiResult.rows.length) {
                        this.setColHeaders()
                        this.columns = this.setColumns()
                        this.setHiddenColumns()
                        this.data = this.apiResult.rows.slice(1, this.apiResult.rows.length)
                        this.setRowStyle()

                        this.loading = false
                    } else {
                        this.noDataMessage = "Nenhuma conta gerencial atrelada ao evento"
                        this.loading = false
                    }

                    if (this.selection.id > 1 && this.chartType) {
                        this.hotInstance = null
                        this.$nextTick(() => {
                            this.createPlotly()
                        })
                    }
                }
            } catch (err) {
                this.$fnError(err)
            } 
        },

        setYearReference(variationId) {
            let yearRef = null

            if (variationId === 5) {
                yearRef = this.yearReference
            } else if (variationId === 6) {
                yearRef = 1
            }
            
            return yearRef 
        },

        setRowStyle() {
            this.rowsStyle = []
            let cssIndex = this.apiResult.rows[0].findIndex(r => r === 'CSS')

            this.data.forEach((row, index) => {
                let type = null

                if (row[0] === null) {
                    row[0] = 'Total'
                }

                if (row[0]?.includes('%')) {
                    type = 'percentage'
                }

                let style = {
                    rowId: index,
                    css: row[cssIndex],
                    type
                }

                this.rowsStyle.push(style)
            })
        },

        setHiddenColumns() {
            this.hiddenColumns = { columns: [], copyPasteEnabled: false }
            let cols = []

            this.columns.forEach(c => {
                let header = this.apiResult.headers.find(h => h.text === c.text)

                if (header) {
                    if (header.hideColumn) {
                        cols.push(c.data)
                    }
                }
            })

            if (!this.year1month && this.variation.id === 6) {
                let col = 1
                for (let c = col; c < col + 4; c++) {
                    cols.push(c)
                }
            } else {
                for (let i = 1; i <= 12; i++) {
                    let col = null
                    let foundMonth = this.year1months.find(m => m.id === i)
    
                    if (!foundMonth) {
                        let initialText = i < 10 ? 'Mês 0' : 'Mês '

                        if (this.variation.id >= 4) {
                            col = this.columns.find(c => c.text.includes(`${initialText}${i} Ano 1`)).data
    
                            if (col) {
                                for (let c = col; c < col + 4; c++) {
                                    cols.push(c)
                                }
                            }
                        } else {
                            col = this.columns.find(c => c.text.includes(`${initialText}${i} Ano 1`)).data
    
                            if (col) {
                                cols.push(col)
                            }
                        }
                    }
                }
            }

            if (this.variation.id !== 4) {
                for (let i = 1; i <= 12; i++) {
                    let col = null
                    let foundMonth = this.year2months.find(m => m.id === i)
                    
                    if (!foundMonth) {
                        let initialText = i < 10 ? 'Mês 0' : 'Mês '
    
                        if (this.variation.id >= 4) {
                            col = this.columns.find(c => c.text.includes(`${initialText}${i} Ano 2`)).data
    
                            if (col) {
                                for (let c = col; c < col + 4; c++) {
                                    cols.push(c)
                                }
                            }
                        } else {
                            col = this.columns.find(c => c.text === `${initialText}${i} Ano 2`).data
    
                            if (col) {
                                cols.push(col)
                            }
                        }
                    }
                }
            }

            if (!this.year1quarter && this.variation.id === 6) {
                let col = 5
                for (let c = col; c < col + 4; c++) {
                    cols.push(c)
                }
            } else {
                for (let i = 1; i <= 4; i++) {
                    let col = null
                    let foundQuarter = this.year1quarters.find(q => q.id === i)

                    if (!foundQuarter) {
                        if (this.variation.id >= 4) {
                            col = this.columns.find(c => c.text.includes(`Tri 0${i} Ano 1`)).data
    
                            if (col) {
                                for (let c = col; c < col + 4; c++) {
                                    cols.push(c)
                                }
                            }
                        } else {
                            col = this.columns.find(c => c.text === `Tri 0${i} Ano 1`).data
    
                            if (col) {
                                cols.push(col)
                            }
                        }
                    }
                }
            }

            for (let i = 1; i <= 4; i++) {
                let col = null
                let foundQuarter = this.year2quarters.find(q => q.id === i)
                
                if (!foundQuarter) {
                    if (this.variation.id >= 4) {
                        col = this.columns.find(c => c.text.includes(`Tri 0${i} Ano 2`)).data

                        if (col) {
                            for (let c = col; c < col + 4; c++) {
                                cols.push(c)
                            }
                        }
                    } else {
                        col = this.columns.find(c => c.text === `Tri 0${i} Ano 2`).data
                        
                        if (col) {
                            cols.push(col)
                        }
                    }
                }
            }

            if (!this.year1semester && this.variation.id === 6) {
                let col = 9
                for (let c = col; c < col + 4; c++) {
                    cols.push(c)
                }
            } else {
                for (let i = 1; i <= 2; i++) {
                    let col = null
                    let foundSemester = this.year1semesters.find(s => s.id === i)
                    
                    if (!foundSemester) {
                        if (this.variation.id >= 4) {
                            col = this.columns.find(c => c.text.includes(`Sem 0${i} Ano 1`)).data

                            if (col) {
                                for (let c = col; c < col + 4; c++) {
                                    cols.push(c)
                                }
                            }
                        } else {
                            col = this.columns.find(c => c.text === `Sem 0${i} Ano 1`).data
                    
                            if (col) {
                                cols.push(col)
                            }
                        }
                    }
                }
            }

            for (let i = 1; i <= 2; i++) {
                let col = null
                let foundSemester = this.year2semesters.find(s => s.id === i)
                
                if (!foundSemester) {
                    if (this.variation.id >= 4) {
                        col = this.columns.find(c => c.text.includes(`Sem 0${i} Ano 2`)).data

                        if (col) {
                            for (let c = col; c < col + 4; c++) {
                                cols.push(c)
                            }
                        }
                    } else {
                        col = this.columns.find(c => c.text === `Sem 0${i} Ano 2`).data
                        
                        if (col) {
                            cols.push(col)
                        }
                    }
                }
            }

            if (!this.startingYear && this.variation.id === 6) {
                let col = 13
                for (let c = col; c < col + 4; c++) {
                    cols.push(c)
                }
            } else {
                for (let i = 1; i <= 2; i++) {
                    let col = null
                    if (i === 1) {
                        if (!this.startingYear) {
                            if (this.variation.id >= 4) {
                                col = this.columns.find(c => c.text.includes(`Total Ano Ano ${i}`)).data
    
                                if (col) {
                                    for (let c = col; c < col + 4; c++) {
                                        cols.push(c)
                                    }
                                }
                            } else {
                                col = this.columns.find(c => c.text === `Total Ano Ano ${i}`).data
    
                                if (col) {
                                    cols.push(col)
                                }
                            }
                        }
                    } else if (i === 2) {
                        if (!this.laterYear) {
                            if (this.variation.id >= 4) {
                                col = this.columns.find(c => c.text.includes(`Total Ano Ano ${i}`)).data
    
                                if (col) {
                                    for (let c = col; c < col + 4; c++) {
                                        cols.push(c)
                                    }
                                }
                            } else {
                                col = this.columns.find(c => c.text === `Total Ano Ano ${i}`).data
    
                                if (col) {
                                    cols.push(col)
                                }
                            }
                        }
                    }
                }
            }

            if (this.variation.id >= 4) {
                if (!this.absoluteVariation) {
                    for (let i = 0; i < this.columns.length; i++) {
                        let col = null

                        if (this.columns[i].text.includes('Var.(-)')) {
                            col = this.columns[i].data
    
                            if (col) {
                                cols.push(col)
                            }
                        }
                    }
                }

                if (!this.percentageVariation) {
                    for (let i = 0; i < this.columns.length; i++) {
                        let col = null

                        if (this.columns[i].text.includes('Var.(%)')) {
                            col = this.columns[i].data
        
                            if (col) {
                                cols.push(col)
                            }
                        }
                    }
                }
            }

            this.hiddenColumns.columns = cols
        },

        setColHeaders() {
            this.colHeaders = []
            this.months = this.$calendar.months()
            this.quarters = this.$calendar.quarters()
            this.semesters = this.$calendar.semesters()

            if (this.variation.id === 5 || this.variation.id === 6) {
                this.apiResult.rows[0].forEach(row => {
                    let colHeader = row

                    let header = this.apiResult.headers.find(h => h.text === row)
                    if (header.valueColumn) {
                        let headerText = ''
                        if (header.value.includes('_event1')) {
                            headerText = this.event1.event
                        } else if (header.value.includes('_event2')) {
                            headerText = this.event2.event
                        } else if (header.value.includes('_variance')) {
                            headerText = "Var.(-)"
                        } else if (header.value.includes('_percentage')) {
                            headerText = "Var.(%)"
                        }
                        
                        colHeader = headerText
                    }

                    this.colHeaders.push(colHeader)
                })
            } else {
                this.apiResult.rows[0].forEach(row => {
                    let colHeader = row
    
                    let header = this.apiResult.headers.find(h => h.text === row)
                    if (header.valueColumn) {
                        let headerText = header.text

                        if (header.value === 'value00') {
                            headerText = "Saldo inicial"
                        } else if(header.value.includes('_variance')){
                            headerText = "Var.(-)"
                        } else if(header.value.includes('_percentage')){
                            headerText = "Var.(%)"
                        } else if (!header.value.includes('year')) {
                            let headerIndex = parseInt(header.value.slice(5, 7), 10)
                            let year = null
                            if (headerIndex > 0 && headerIndex <= 12) {
                                if (this.months[headerIndex - 1].id >= this.fiscalYearStartMonth) {
                                    year = this.year1
                                } else {
                                    year = this.year2
                                }
                                headerText = `${this.months[headerIndex - 1].text.slice(0,3)}/${year.toString().slice(2,4)}`
                            } else if (headerIndex >= 13 && headerIndex <= 24) {
                                if (this.months[headerIndex - 1 - 12].id >= this.fiscalYearStartMonth) {
                                    year = this.year2
                                } else {
                                    year = parseInt(this.year2, 10) + 1
                                }
                                headerText = `${this.months[headerIndex - 1 - 12].text.slice(0,3)}/${year.toString().slice(2,4)}`
                            }
                        }
                        colHeader = headerText
                    }
    
                    this.colHeaders.push(colHeader)
                })
            }

            const dates = ["Janeiro", "Fevereiro", "Março", "1º Trimestre", "Abril", "Maio", "Junho", "2º Trimestre", "1º Semestre", "Julho", "Agosto", "Setembro", "3º Trimestre", "Outubro", "Novembro", "Dezembro", "4º Trimestre", "2º Semestre", "Ano"]

            if (this.variation.id < 4) {
                this.nestedHeaders = false
                this.columnHeaderHeight = 50
            } else if (this.variation.id === 4) {
                this.nestedHeaders = [[], this.colHeaders]
                this.nestedHeaders[0].push({ label: "", colspan: this.firstNestedColspan })

                for (let i = 0; i < dates.length ; i++) {
                    this.nestedHeaders[0].push({ label: dates[i], colspan: 4 })
                }

                this.columnHeaderHeight = [25, 50]
            } else if (this.variation.id === 5) {
                this.nestedHeaders = [[], this.colHeaders]
                this.nestedHeaders[0].push({ label: "", colspan: this.firstNestedColspan })

                let year = this.yearReference === 1 ? this.year1 : this.year2

                for (let i = 0; i < dates.length; i++) {
                    this.nestedHeaders[0].push({ label: `${dates[i]} ${year}`, colspan: 4 })
                }

                for (let i = 0; i < dates.length; i++) {
                    this.nestedHeaders[0].push({ label: `${dates[i]} ${year}`, colspan: 4 })
                }

                this.columnHeaderHeight = [25, 50]
            } else if (this.variation.id === 6) {
                this.nestedHeaders = [[], this.colHeaders]
                this.nestedHeaders[0].push({ label: "", colspan: this.firstNestedColspan })

                let currentMonthText = this.months.find(m => m.id === this.currentMonth).text
                this.nestedHeaders[0].push({ label: currentMonthText, colspan: 4 })

                // let currentQuarterText = this.quarters.find(q => q.id === Math.floor(this.currentMonth / 3  + 1)).text
                // this.nestedHeaders[0].push({ label: currentQuarterText, colspan: 4 })
                this.nestedHeaders[0].push({ label: 'Quarter to date', colspan: 4 })

                // let currentSemesterText = this.semesters.find(s => s.id === Math.floor(this.currentMonth / 6  + 1)).text
                // this.nestedHeaders[0].push({ label: currentSemesterText, colspan: 4 })
                this.nestedHeaders[0].push({ label: 'Semester to date', colspan: 4 })

                // this.nestedHeaders[0].push({ label: 'Ano', colspan: 4 })
                this.nestedHeaders[0].push({ label: 'Year to date', colspan: 4 })

                this.columnHeaderHeight = [25, 50]
            }
        },

        setColumns() {
            let columns = []

            this.apiResult.rows[0].forEach((row, index) => {
                let header = this.analyseHeaders.find(h => h.text === row && h.visible)
                if (!header) return

                if (header.visible) {
                    let foundColumn = this.apiResult.columns.find(c => c.columnAlias === header.name)
                    let data = index
                    let text = header.text

                    let readOnly = null
                    if (header.editable) {
                        readOnly = false
                    } else {
                        readOnly = true
                    }

                    let type = null
                    let className = ''
                    let numericFormat = null
                    if (foundColumn.columnType === 'VARCHAR') {
                        type = 'text'
                        className = ' htLeft'
                    } else if (foundColumn.columnType === 'INT') {
                        type = 'numeric'
                        className = ' htRight'
                        numericFormat = {
                            pattern: {
                                thousandSeparated: false,
                                mantissa: 0,
                            },
                        }
                    } else {
                        let endpoint = this.setEndpoint()
                        if (endpoint === 'employee-allocation-planning') {
                            type = 'numeric'
                            numericFormat = {
                                pattern: {
                                    output: "percent",
                                    negative: "parenthesis",
                                    mantissa: 2,
                                },
                                culture: ptBR.languageCode
                            }
                        } else {
                            type = 'numeric'
                            className = ' htRight'
                            numericFormat = {
                                pattern: {
                                    thousandSeparated: true,
                                    negative: "parenthesis",
                                    mantissa: 2,
                                },
                                culture: ptBR.languageCode
                            }
                        }
                    }

                    const totalColumns = ['year1quarter1', 'year1quarter2', 'year1quarter3', 'year1quarter4', 'year1semester1', 'year1semester2', 'year1',
                        'year2quarter1', 'year2quarter2', 'year2quarter3', 'year2quarter4', 'year2semester1', 'year2semester2', 'year2']

                    if (totalColumns.includes(foundColumn.columnAlias)) {
                        className += ' border-sides-1 font-bold'
                    }

                    columns.push({ data, text, readOnly, className, numericFormat, type})
                }
            })

            return columns
        },

        setFilters() {
            this.filters = []
            this.displayFilters = []

            this.analyseHeaders.forEach(header => {
                if (header.filter) {
                    this.filters.push({
                        ...header,
                        items: [],
                        selectedItems: [],
                    })
                }

                if (header.select) {
                    this.displayFilters.push({
                        ...header
                    })
                }
            })
            
            let filterConditions = this.lastRefreshParams.filterConditions

            if (filterConditions) {
                for (let key in filterConditions) {
                    let foundFilter = this.filters.find(f => f.columnId === key)
                    this.showFilterItems(foundFilter).then(() => {
                        filterConditions[key].forEach(c => {
                            let foundSelectedItem = foundFilter.items.find(f => f.id === c)
                            if (foundSelectedItem) foundFilter.selectedItems.push(foundSelectedItem)
                        })
                    })
                }
            }

            this.selectedDisplayFilters = []
            this.displayFilters.forEach(f => {
                if (f.visible) {
                    this.selectedDisplayFilters.push(f)
                }
            })

            if (this.variation.id === 2 || this.variation.id === 3) {
                let eventIndex = this.displayFilters.findIndex(d => d.name === 'event')
                this.displayFilters[eventIndex].visible = true

                let idEventIndex = this.analyseHeaders.findIndex(d => d.name === 'id_event')
                this.analyseHeaders[idEventIndex].visible = true
            }
        },

        setFirstNestedColspan() {
            this.firstNestedColspan = 0

            this.displayFilters.forEach(f => {
                if (f.visible) {
                    this.firstNestedColspan++
                }
            })
        },

        async showFilterItems(filter) {
            if (!filter.items.length) {
                this.loadingFilters.push(filter.name)

                let payload
                if (filter.conditions) {
                    if (this.multipleEvents) {
                        filter.conditions.forEach(c => {
                            if (!c.value) c.value = this.events[0][c.column]
                        })
                    } else {
                        filter.conditions.forEach(c => {
                            if (!c.value) c.value = this.event1[c.column]
                        })
                    }

                    payload = {
                        filter: {
                            conditions: [
                                ...filter.conditions
                            ]
                        }
                    }
                }

                try {
                    let res = await this.$http.post(filter.server + '/list-options', { ...payload })
                    if (res) {
                        let foundFilter = this.filters.find(f => f.columnId === filter.columnId)
                        foundFilter.items = res.data.rows

                        this.loadingFilters.pop()
                    }
                } catch (err) {
                    this.loadingFilters.pop()
                    this.$fnError(err)
                }
            }
        },

        setPayloadFilter(filter, e) {
            this.filterConditions[filter.columnId] = []
            if (e) {
                e.forEach(selectedFilter => {
                    this.filterConditions[filter.columnId].push(selectedFilter.id)
                })
            } else {
                this.filterConditions[filter.columnId] = null
            }
        },

        setPayloadDisplayFilter(displayFilter, e) {
            let headerIndex = this.analyseHeaders.findIndex(h => h.name === displayFilter.name)
            this.analyseHeaders[headerIndex].visible = e

            let displayIndex = this.selectedDisplayFilters.findIndex(f => f.name === displayFilter.name)
            
            if (e) {
                this.selectedDisplayFilters.push(displayFilter)
                this.analyseHeaders.splice(headerIndex, 1)
                this.analyseHeaders.splice(this.selectedDisplayFilters.length - 1, 0, displayFilter)
            } else {
                if (displayIndex >= 0) {
                    this.selectedDisplayFilters.splice(displayIndex, 1)
                }
            }

            if (!!this.displayFilters.length && !this.selectedDisplayFilters.length) {
                return this.$toast.error('Selecione pelo menos uma coluna para exibir')
            }
        },

        filterIsLoading(filter) {
            if (this.loadingFilters.includes(filter.name)) {
                return true
            } else {
                return false
            }
        },
        
        addPreventCloseHandler() {
            window.addEventListener('beforeunload', this.preventCloseWindow)
        },

        removePreventCloseHandler() {
            window.removeEventListener('beforeunload', this.preventCloseWindow)
        },

        preventCloseWindow(e) {
            if (this.unsavedChanges) {
                e.preventDefault()
                e.returnValue = ''
            }
        },

        isTotalDateColumn(col) {
            for (let i = 0; i <= 4; i++) {
                if (col.includes(`Tri 0${i}`) || col.includes(`Sem 0${i}`) || col.includes('Ano Ano') || col.includes('Total Ano')) {
                    return true
                }
            }
        },

        toggleAbsoluteVariation() {
            let plugin = this.hotInstance.getPlugin('hiddenColumns')
            let col = null
            let cols = []

            if (this.variation.id === 4 || this.variation.id === 5) {
                for (let i = 0; i < this.columns.length; i++) {
                    if (this.columns[i].text.includes('Var.(-)')) {
                        col = this.columns[i].data

                        let dateCols = []
                        for (let i = 1; i <= 12; i++) {
                            let foundMonth = this.year1months.find(m => m.id === i)
                            let initialText = i < 10 ? 'Mês 0' : 'Mês '
                            
                            if (!foundMonth) {
                                let dateCol = this.columns.find(c => c.text.includes(`${initialText}${i} Ano 1`)).data
                                dateCols.push(dateCol)
                            }
                        }

                        for (let i = 1; i <= 12; i++) {
                            let foundMonth = this.year2months.find(m => m.id === i)
                            let initialText = i < 10 ? 'Mês 0' : 'Mês '
                            
                            if (!foundMonth) {
                                let dateCol = this.columns.find(c => c.text.includes(`${initialText}${i} Ano 2`)).data
                                dateCols.push(dateCol)
                            }
                        }

                        for (let i = 1; i <= 4; i++) {
                            let foundQuarter = this.year1quarters.find(q => q.id === i)
                            
                            if (!foundQuarter) {
                                let dateCol = this.columns.find(c => c.text.includes(`Tri 0${i} Ano 1`)).data
                                dateCols.push(dateCol)
                            }
                        }

                        for (let i = 1; i <= 4; i++) {
                            let foundQuarter = this.year2quarters.find(q => q.id === i)
                            
                            if (!foundQuarter) {
                                let dateCol = this.columns.find(c => c.text.includes(`Tri 0${i} Ano 2`)).data
                                dateCols.push(dateCol)
                            }
                        }

                        for (let i = 1; i <= 2; i++) {
                            let foundSemester = this.year1semesters.find(s => s.id === i)
                            
                            if (!foundSemester) {
                                let dateCol = this.columns.find(c => c.text.includes(`Sem 0${i} Ano 1`)).data
                                dateCols.push(dateCol)
                            }
                        }

                        for (let i = 1; i <= 2; i++) {
                            let foundSemester = this.year2semesters.find(s => s.id === i)
                            
                            if (!foundSemester) {
                                let dateCol = this.columns.find(c => c.text.includes(`Sem 0${i} Ano 2`)).data
                                dateCols.push(dateCol)
                            }
                        }

                        if (!this.startingYear) {
                            let dateCol = this.columns.find(c => c.text.includes(`Total Ano Ano 1`)).data
                            dateCols.push(dateCol)
                        }

                        if (!this.laterYear) {
                            let dateCol = this.columns.find(c => c.text.includes(`Total Ano Ano 2`)).data
                            dateCols.push(dateCol)
                        }

                        if (col && !dateCols.includes(col - 2)) {
                            cols.push(col)
                        }
                    }
                }
            } else if (this.variation.id === 6) {
                for (let i = 0; i < this.columns.length; i++) {
                    if (this.columns[i].text.includes('Var.(-)')) {
                        let col = this.columns[i].data
                        let dateCol = null
                        let dateCols = []

                        if (!this.year1month) {
                            dateCol = 1
                            dateCols.push(dateCol)
                        }

                        if (!this.year1quarter) {
                            dateCol = 5
                            dateCols.push(dateCol)
                        }

                        if (!this.year1semester) {
                            dateCol = 9
                            dateCols.push(dateCol)
                        }

                        if (!this.startingYear) {
                            dateCol = 13
                            dateCols.push(dateCol)
                        }

                        if (col && !dateCols.includes(col - 2)) {
                            cols.push(col)
                        }
                    }
                }
            }

            if (this.absoluteVariation) {
                plugin.showColumns(cols)
            } else {
                plugin.hideColumns(cols)
            }

            this.hotInstance.render()
        },

        togglePercentageVariation() {
            let plugin = this.hotInstance.getPlugin('hiddenColumns')
            let col = null
            let cols = []

            if (this.variation.id === 4 || this.variation.id === 5) {
                for (let i = 0; i < this.columns.length; i++) {
                    if (this.columns[i].text.includes('Var.(%)')) {
                        col = this.columns[i].data

                        let dateCols = []
                        for (let i = 1; i <= 12; i++) {
                            let foundMonth = this.year1months.find(m => m.id === i)
                            let initialText = i < 10 ? 'Mês 0' : 'Mês '
                            
                            if (!foundMonth) {
                                let dateCol = this.columns.find(c => c.text.includes(`${initialText}${i} Ano 1`)).data
                                dateCols.push(dateCol)
                            }
                        }

                        for (let i = 1; i <= 12; i++) {
                            let foundMonth = this.year2months.find(m => m.id === i)
                            let initialText = i < 10 ? 'Mês 0' : 'Mês '
                            
                            if (!foundMonth) {
                                let dateCol = this.columns.find(c => c.text.includes(`${initialText}${i} Ano 2`)).data
                                dateCols.push(dateCol)
                            }
                        }

                        for (let i = 1; i <= 4; i++) {
                            let foundQuarter = this.year1quarters.find(q => q.id === i)
                            
                            if (!foundQuarter) {
                                let dateCol = this.columns.find(c => c.text.includes(`Tri 0${i} Ano 1`)).data
                                dateCols.push(dateCol)
                            }
                        }

                        for (let i = 1; i <= 4; i++) {
                            let foundQuarter = this.year2quarters.find(q => q.id === i)
                            
                            if (!foundQuarter) {
                                let dateCol = this.columns.find(c => c.text.includes(`Tri 0${i} Ano 2`)).data
                                dateCols.push(dateCol)
                            }
                        }

                        for (let i = 1; i <= 2; i++) {
                            let foundSemester = this.year1semesters.find(s => s.id === i)
                            
                            if (!foundSemester) {
                                let dateCol = this.columns.find(c => c.text.includes(`Sem 0${i} Ano 1`)).data
                                dateCols.push(dateCol)
                            }
                        }

                        for (let i = 1; i <= 2; i++) {
                            let foundSemester = this.year2semesters.find(s => s.id === i)
                            
                            if (!foundSemester) {
                                let dateCol = this.columns.find(c => c.text.includes(`Sem 0${i} Ano 2`)).data
                                dateCols.push(dateCol)
                            }
                        }

                        if (!this.startingYear) {
                            let dateCol = this.columns.find(c => c.text.includes(`Total Ano Ano 1`)).data
                            dateCols.push(dateCol)
                        }

                        if (!this.laterYear) {
                            let dateCol = this.columns.find(c => c.text.includes(`Total Ano Ano 2`)).data
                            dateCols.push(dateCol)
                        }

                        if (col && !dateCols.includes(col - 3)) {
                            cols.push(col)
                        }
                    }
                }
            } else if (this.variation.id === 6) {
                for (let i = 0; i < this.columns.length; i++) {
                    if (this.columns[i].text.includes('Var.(%)')) {
                        let col = this.columns[i].data
                        let dateCol = null
                        let dateCols = []

                        if (!this.year1month) {
                            dateCol = 1
                            dateCols.push(dateCol)
                        }

                        if (!this.year1quarter) {
                            dateCol = 5
                            dateCols.push(dateCol)
                        }

                        if (!this.year1semester) {
                            dateCol = 9
                            dateCols.push(dateCol)
                        }

                        if (!this.startingYear) {
                            dateCol = 13
                            dateCols.push(dateCol)
                        }

                        if (col && !dateCols.includes(col - 3)) {
                            cols.push(col)
                        }
                    }
                }
            }

            if (this.percentageVariation) {
                plugin.showColumns(cols)
            } else {
                plugin.hideColumns(cols)
            }

            this.hotInstance.render()
        },
        
        setDateColumns() {
            this.startingYear = true
            this.year1month =  true
            this.year1quarter =  true
            this.year1semester =  true
            this.laterYear =  true
            this.year2month =  true
            this.year2quarter =  true
            this.year2semester =  true
            this.laterYear = true
            this.absoluteVariation = true
            this.percentageVariation = true

            if (this.events.length) {
                this.startingMonthIndex = this.events[0].fiscal_year_start_month - 1
            } else {
                this.startingMonthIndex = this.event1.fiscal_year_start_month - 1
            }

            this.months = this.$calendar.months()
            this.quarters = this.$calendar.quarters()
            this.semesters = this.$calendar.semesters()

            let _months = []
            this.months.forEach(month => {
                if (month.id - 1 < this.startingMonthIndex) {
                    _months.splice(this.startingMonthIndex + month.id - 1, 0, month)
                } else {
                    _months.splice(month.id - 1 - this.startingMonthIndex, 0, month)
                }
            })

            this.months = _months
            this.year1months = this.months
            this.year2months = this.months
            this.year1quarters = this.quarters
            this.year2quarters = this.quarters
            this.year1semesters = this.semesters
            this.year2semesters = this.semesters
        },

        showAllMonths(year) {
            let plugin = this.hotInstance.getPlugin('hiddenColumns')
            let col = null
            let cols = []

            if (this.variation.id === 5) {
                for (let i = 1; i <= 12; i++) {
                    let initialText = i < 10 ? 'Mês 0' : 'Mês '

                    col = this.columns.find(c => c.text.includes(`${initialText}${i} Ano ${year}`)).data

                    if (this.year1month || this.year2month) {
                        for (let c = col; c < col + 4; c++) {
                            let factor = c - col

                            if (factor === 2) {
                                if (this.absoluteVariation) {
                                    cols.push(c)
                                }
                            } else if (factor === 3) {
                                if (this.percentageVariation) {
                                    cols.push(c)
                                }
                            } else {
                                cols.push(c)
                            }
                        }
                    } else {
                        for (let c = col; c < col + 4; c++) {
                            cols.push(c)
                        }
                    }
                }
            } else if (this.variation.id === 4) {
                for (let i = 1; i <= 12; i++) {
                    let initialText = i < 10 ? 'Mês 0' : 'Mês '

                    col = this.columns.find(c => c.text.includes(`${initialText}${i} Ano ${year}`)).data

                    if (this.year1month) {
                        this.year1months = this.months
                        this.year2months = this.months

                        for (let c = col; c < col + 4; c++) {
                            let factor = c - col

                            if (factor === 2) {
                                if (this.absoluteVariation) {
                                    cols.push(c)
                                }
                            } else if (factor === 3) {
                                if (this.percentageVariation) {
                                    cols.push(c)
                                }
                            } else {
                                cols.push(c)
                            }
                        }
                    } else {
                        this.year1months = []
                        this.year2months = []
                        
                        for (let c = col; c < col + 4; c++) {
                            cols.push(c)
                        }
                    }
                }
            } else {
                for (let i = 1; i <= 12; i++) {
                    let initialText = i < 10 ? 'Mês 0' : 'Mês '

                    col = this.columns.find(c => c.text === `${initialText}${i} Ano ${year}`).data
                    
                    cols.push(col)
                }
            }

            if (year === 1) {
                if (this.year1month) {
                    this.year1months = this.months
                    plugin.showColumns(cols)
                } else {
                    this.year1months = []
                    plugin.hideColumns(cols)
                }
            } else {
                if (this.year2month) {
                    this.year2months = this.months
                    plugin.showColumns(cols)
                } else {
                    this.year2months = []
                    plugin.hideColumns(cols)
                }
            }

            this.hotInstance.render()
        },

        showMonth(year) {
            let plugin = this.hotInstance.getPlugin('hiddenColumns')

            if (this.variation.id === 5) {
                if (year === 1) {
                    for (let i = 1; i <= 12; i++) {
                        let foundMonth = this.year1months.find(m => m.id === i)
                        let initialText = i < 10 ? 'Mês 0' : 'Mês '
                        
                        let col = this.columns.find(c => c.text.includes(`${initialText}${i} Ano ${year}`)).data

                        if (foundMonth) {
                            for (let c = col; c < col + 4; c++) {
                                let factor = c - col

                                if (factor === 2) {
                                    if (this.absoluteVariation) {
                                        plugin.showColumn(c)
                                    }
                                } else if (factor === 3) {
                                    if (this.percentageVariation) {
                                        plugin.showColumn(c)
                                    }
                                } else {
                                    plugin.showColumn(c)
                                }
                            }
                        } else {
                            for (let c = col; c < col + 4; c++) {
                                plugin.hideColumn(c)
                            }
                        }
                    }
                } else {
                    for (let i = 1; i <= 12; i++) {
                        let foundMonth = this.year2months.find(m => m.id === i)
                        let initialText = i < 10 ? 'Mês 0' : 'Mês '

                        let col = this.columns.find(c => c.text.includes(`${initialText}${i} Ano ${year}`)).data
                        
                        if (foundMonth) {
                            for (let c = col; c < col + 4; c++) {
                                let factor = c - col

                                if (factor === 2) {
                                    if (this.absoluteVariation) {
                                        plugin.showColumn(c)
                                    }
                                } else if (factor === 3) {
                                    if (this.percentageVariation) {
                                        plugin.showColumn(c)
                                    }
                                } else {
                                    plugin.showColumn(c)
                                }
                            }
                        } else {
                            for (let c = col; c < col + 4; c++) {
                                plugin.hideColumn(c)
                            }
                        }
                    }
                }
            } else if (this.variation.id === 4) {
                for (let i = 1; i <= 12; i++) {
                    let foundMonth = this.year1months.find(m => m.id === i)
                    let initialText = i < 10 ? 'Mês 0' : 'Mês '

                    let col = this.columns.find(c => c.text.includes(`${initialText}${i} Ano ${year}`)).data

                    if (foundMonth) {
                        for (let c = col; c < col + 4; c++) {
                            let factor = c - col

                            if (factor === 2) {
                                if (this.absoluteVariation) {
                                    plugin.showColumn(c)
                                }
                            } else if (factor === 3) {
                                if (this.percentageVariation) {
                                    plugin.showColumn(c)
                                }
                            } else {
                                plugin.showColumn(c)
                            }
                        }
                    } else {
                        for (let c = col; c < col + 4; c++) {
                            plugin.hideColumn(c)
                        }
                    }
                }
            } else if (this.variation.id === 6) {
                let col = 1
                
                if (this.year1month) {
                    for (let c = col; c < col + 4; c++) {
                        let factor = c - col

                        if (factor === 2) {
                            if (this.absoluteVariation) {
                                plugin.showColumn(c)
                            }
                        } else if (factor === 3) {
                            if (this.percentageVariation) {
                                plugin.showColumn(c)
                            }
                        } else {
                            plugin.showColumn(c)
                        }
                    }
                } else {
                    for (let c = col; c < col + 4; c++) {
                        plugin.hideColumn(c)
                    }
                }
            } else {
                if (year === 1) {
                    for (let i = 1; i <= 12; i++) {
                        let foundMonth = this.year1months.find(m => m.id === i)
                        let initialText = i < 10 ? 'Mês 0' : 'Mês '

                        let col = this.columns.find(c => c.text === `${initialText}${i} Ano ${year}`).data
                        
                        if (foundMonth) {
                            plugin.showColumn(col)
                        } else {
                            plugin.hideColumn(col)
                        }
                    }
                } else {
                    for (let i = 1; i <= 12; i++) {
                        let foundMonth = this.year2months.find(m => m.id === i)
                        let initialText = i < 10 ? 'Mês 0' : 'Mês '
                        let col = this.columns.find(c => c.text === `${initialText}${i} Ano ${year}`).data
                        
                        if (foundMonth) {
                            plugin.showColumn(col)
                        } else {
                            plugin.hideColumn(col)
                        }
                    }
                }
            }

            if (this.year1months.length && this.variation.id !== 6) {
                this.year1month = true
            }

            if (this.year2months.length) {
                this.year2month = true
            }

            this.hotInstance.render()
        },

        showAllQuarters(year) {
            let plugin = this.hotInstance.getPlugin('hiddenColumns')
            let cols = []
            let col = null

            if (this.variation.id === 5) {
                for (let i = 1; i <= 4; i++) {
                    col = this.columns.find(c => c.text.includes(`Tri 0${i} Ano ${year}`)).data
                    
                    if (this.year1quarter || this.year2quarter) {
                        for (let c = col; c < col + 4; c++) {
                            let factor = c - col

                            if (factor === 2) {
                                if (this.absoluteVariation) {
                                    cols.push(c)
                                }
                            } else if (factor === 3) {
                                if (this.percentageVariation) {
                                    cols.push(c)
                                }
                            } else {
                                cols.push(c)
                            }
                        }
                    } else {
                        for (let c = col; c < col + 4; c++) {
                            cols.push(c)
                        }
                    }
                }
            } else if (this.variation.id === 4) {
                for (let i = 1; i <= 4; i++) {
                    col = this.columns.find(c => c.text.includes(`Tri 0${i} Ano ${year}`)).data

                    if (this.year1quarter) {
                        for (let c = col; c < col + 4; c++) {
                            let factor = c - col

                            if (factor === 2) {
                                if (this.absoluteVariation) {
                                    cols.push(c)
                                }
                            } else if (factor === 3) {
                                if (this.percentageVariation) {
                                    cols.push(c)
                                }
                            } else {
                                cols.push(c)
                            }
                        }
                    } else {
                        for (let c = col; c < col + 4; c++) {
                            cols.push(c)
                        }
                    }
                }
            } else {
                for (let i = 1; i <= 4; i++) {
                    col = this.columns.find(c => c.text === `Tri 0${i} Ano ${year}`).data
                    cols.push(col)
                }
            }
            
            if (year === 1) {
                if (this.year1quarter) {
                    this.year1quarters = this.quarters
                    plugin.showColumns(cols)
                } else {
                    this.year1quarters = []
                    plugin.hideColumns(cols)
                }
            } else if (year === 2) {
                if (this.year2quarter) {
                    this.year2quarters = this.quarters
                    plugin.showColumns(cols)
                } else {
                    this.year2quarters = []
                    plugin.hideColumns(cols)
                }
            }

            this.hotInstance.render()
        },

        showQuarter(year) {
            let plugin = this.hotInstance.getPlugin('hiddenColumns')

            if (this.variation.id === 5) {
                if (year === 1) {
                    for (let i = 1; i <= 4; i++) {
                        let col = null
                        let foundQuarter = this.year1quarters.find(q => q.id === i)
                        
                        if (foundQuarter) {
                            col = this.columns.find(c => c.text.includes(`Tri 0${i} Ano ${year}`)).data
                            for (let c = col; c < col + 4; c++) {
                                let factor = c - col

                                if (factor === 2) {
                                    if (this.absoluteVariation) {
                                        plugin.showColumn(c)
                                    }
                                } else if (factor === 3) {
                                    if (this.percentageVariation) {
                                        plugin.showColumn(c)
                                    }
                                } else {
                                    plugin.showColumn(c)
                                }
                            }
                        } else {
                            col = this.columns.find(c => c.text.includes(`Tri 0${i} Ano ${year}`)).data
                            for (let c = col; c < col + 4; c++) {
                                plugin.hideColumn(c)
                            }
                        }
                    }
                } else {
                    for (let i = 1; i <= 4; i++) {
                        let col = null
                        let foundQuarter = this.year2quarters.find(q => q.id === i)
                        
                        if (foundQuarter) {
                            col = this.columns.find(c => c.text.includes(`Tri 0${i} Ano ${year}`)).data
                            for (let c = col; c < col + 4; c++) {
                                let factor = c - col

                                if (factor === 2) {
                                    if (this.absoluteVariation) {
                                        plugin.showColumn(c)
                                    }
                                } else if (factor === 3) {
                                    if (this.percentageVariation) {
                                        plugin.showColumn(c)
                                    }
                                } else {
                                    plugin.showColumn(c)
                                }
                            }
                        } else {
                            col = this.columns.find(c => c.text.includes(`Tri 0${i} Ano ${year}`)).data
                            for (let c = col; c < col + 4; c++) {
                                plugin.hideColumn(c)
                            }
                        }
                    }
                }
            } else if (this.variation.id === 4) {
                for (let i = 1; i <= 4; i++) {
                    let col = null
                    let foundQuarter = this.year1quarters.find(m => m.id === i)

                    if (foundQuarter) {
                        col = this.columns.find(c => c.text.includes(`Tri 0${i} Ano ${year}`)).data
    
                        for (let c = col; c < col + 4; c++) {
                            let factor = c - col

                            if (factor === 2) {
                                if (this.absoluteVariation) {
                                    plugin.showColumn(c)
                                }
                            } else if (factor === 3) {
                                if (this.percentageVariation) {
                                    plugin.showColumn(c)
                                }
                            } else {
                                plugin.showColumn(c)
                            }
                        }
                    } else {
                        col = this.columns.find(c => c.text.includes(`Tri 0${i} Ano ${year}`)).data
                        
                        for (let c = col; c < col + 4; c++) {
                            plugin.hideColumn(c)
                        }
                    }
                }
            } else if (this.variation.id === 6) {
                let col = 5
                
                if (this.year1quarter) {
                    for (let c = col; c < col + 4; c++) {
                        let factor = c - col

                        if (factor === 2) {
                            if (this.absoluteVariation) {
                                plugin.showColumn(c)
                            }
                        } else if (factor === 3) {
                            if (this.percentageVariation) {
                                plugin.showColumn(c)
                            }
                        } else {
                            plugin.showColumn(c)
                        }
                    }
                } else {
                    for (let c = col; c < col + 4; c++) {
                        plugin.hideColumn(c)
                    }
                }
            } else {
                if (year === 1) {
                    for (let i = 1; i <= 4; i++) {
                        let col = null
                        let foundQuarter = this.year1quarters.find(q => q.id === i)
                        
                        if (foundQuarter) {
                            col = this.columns.find(c => c.text === `Tri 0${i} Ano ${year}`).data
                            plugin.showColumn(col)
                        } else {
                            col = this.columns.find(c => c.text === `Tri 0${i} Ano ${year}`).data
                            plugin.hideColumn(col)
                        }
                    }
                } else {
                    for (let i = 1; i <= 4; i++) {
                        let col = null
                        let foundQuarter = this.year2quarters.find(q => q.id === i)
                        
                        if (foundQuarter) {
                            col = this.columns.find(c => c.text === `Tri 0${i} Ano ${year}`).data
                            plugin.showColumn(col)
                        } else {
                            col = this.columns.find(c => c.text === `Tri 0${i} Ano ${year}`).data
                            plugin.hideColumn(col)
                        }
                    }
                }
            }

            if (this.year1quarters.length && this.variation.id !== 6) {
                this.year1quarter = true
            }

            if (this.year2quarters.length) {
                this.year2quarter = true
            }

            this.hotInstance.render()
        },

        showAllSemesters(year) {
            let plugin = this.hotInstance.getPlugin('hiddenColumns')
            let cols = []
            let col = null

            if (this.variation.id === 5) {
                for (let i = 1; i <= 2; i++) {
                    col = this.columns.find(c => c.text.includes(`Sem 0${i} Ano ${year}`)).data

                    if (this.year1semester || this.year2semester) {
                        for (let c = col; c < col + 4; c++) {
                            let factor = c - col

                            if (factor === 2) {
                                if (this.absoluteVariation) {
                                    cols.push(c)
                                }
                            } else if (factor === 3) {
                                if (this.percentageVariation) {
                                    cols.push(c)
                                }
                            } else {
                                cols.push(c)
                            }
                        }
                    } else {
                        for (let c = col; c < col + 4; c++) {
                            cols.push(c)
                        }
                    }
                }
            } else if (this.variation.id === 4) {
                for (let i = 1; i <= 2; i++) {
                    col = this.columns.find(c => c.text.includes(`Sem 0${i} Ano ${year}`)).data

                    if (this.year1semester) {
                        for (let c = col; c < col + 4; c++) {
                            let factor = c - col

                            if (factor === 2) {
                                if (this.absoluteVariation) {
                                    cols.push(c)
                                }
                            } else if (factor === 3) {
                                if (this.percentageVariation) {
                                    cols.push(c)
                                }
                            } else {
                                cols.push(c)
                            }
                        }
                    } else {
                        for (let c = col; c < col + 4; c++) {
                            cols.push(c)
                        }
                    }
                }
            } else {
                for (let i = 1; i <= 2; i++) {
                    col = this.columns.find(c => c.text === `Sem 0${i} Ano ${year}`).data
                    cols.push(col)
                }
            }
            
            if (year === 1) {
                if (this.year1semester) {
                    this.year1semesters = this.semesters
                    plugin.showColumns(cols)
                } else {
                    this.year1semesters = []
                    plugin.hideColumns(cols)
                }
            } else if (year === 2) {
                if (this.year2semester) {
                    this.year2semesters = this.semesters
                    plugin.showColumns(cols)
                } else {
                    this.year2semesters = []
                    plugin.hideColumns(cols)
                }
            }

            this.hotInstance.render()
        },

        showSemester(year) {
            let plugin = this.hotInstance.getPlugin('hiddenColumns')

            if (this.variation.id === 5) {
                if (year === 1) {
                    for (let i = 1; i <= 2; i++) {
                        let col = null
                        let foundSemester = this.year1semesters.find(s => s.id === i)
                        
                        if (foundSemester) {
                            col = this.columns.find(c => c.text.includes(`Sem 0${i} Ano ${year}`)).data
                            for (let c = col; c < col + 4; c++) {
                                let factor = c - col

                                if (factor === 2) {
                                    if (this.absoluteVariation) {
                                        plugin.showColumn(c)
                                    }
                                } else if (factor === 3) {
                                    if (this.percentageVariation) {
                                        plugin.showColumn(c)
                                    }
                                } else {
                                    plugin.showColumn(c)
                                }
                            }
                        } else {
                            col = this.columns.find(c => c.text.includes(`Sem 0${i} Ano ${year}`)).data
                            for (let c = col; c < col + 4; c++) {
                                plugin.hideColumn(c)
                            }
                        }
                    }
                } else {
                    for (let i = 1; i <= 2; i++) {
                        let col = null
                        let foundSemester = this.year2semesters.find(s => s.id === i)
                        
                        if (foundSemester) {
                            col = this.columns.find(c => c.text.includes(`Sem 0${i} Ano ${year}`)).data
                            for (let c = col; c < col + 4; c++) {
                                let factor = c - col

                                if (factor === 2) {
                                    if (this.absoluteVariation) {
                                        plugin.showColumn(c)
                                    }
                                } else if (factor === 3) {
                                    if (this.percentageVariation) {
                                        plugin.showColumn(c)
                                    }
                                } else {
                                    plugin.showColumn(c)
                                }
                            }
                        } else {
                            col = this.columns.find(c => c.text.includes(`Sem 0${i} Ano ${year}`)).data
                            for (let c = col; c < col + 4; c++) {
                                plugin.hideColumn(c)
                            }
                        }
                    }
                }
            } else if (this.variation.id === 4) {
                for (let i = 1; i <= 2; i++) {
                    let col = null
                    let foundSemester = this.year1semesters.find(m => m.id === i)

                    if (foundSemester) {
                        col = this.columns.find(c => c.text.includes(`Sem 0${i} Ano ${year}`)).data
    
                        for (let c = col; c < col + 4; c++) {
                            let factor = c - col

                            if (factor === 2) {
                                if (this.absoluteVariation) {
                                    plugin.showColumn(c)
                                }
                            } else if (factor === 3) {
                                if (this.percentageVariation) {
                                    plugin.showColumn(c)
                                }
                            } else {
                                plugin.showColumn(c)
                            }
                        }
                    } else {
                        col = this.columns.find(c => c.text.includes(`Sem 0${i} Ano ${year}`)).data
                        
                        for (let c = col; c < col + 4; c++) {
                            plugin.hideColumn(c)
                        }
                    }
                }
            } else if (this.variation.id === 6) {
                let col = 9
                
                if (this.year1semester) {
                    for (let c = col; c < col + 4; c++) {
                        let factor = c - col

                        if (factor === 2) {
                            if (this.absoluteVariation) {
                                plugin.showColumn(c)
                            }
                        } else if (factor === 3) {
                            if (this.percentageVariation) {
                                plugin.showColumn(c)
                            }
                        } else {
                            plugin.showColumn(c)
                        }
                    }
                } else {
                    for (let c = col; c < col + 4; c++) {
                        plugin.hideColumn(c)
                    }
                }
            } else {
                if (year === 1) {
                    for (let i = 1; i <= 2; i++) {
                        let col = null
                        let foundSemester = this.year1semesters.find(s => s.id === i)
                        
                        if (foundSemester) {
                            col = this.columns.find(c => c.text === `Sem 0${i} Ano ${year}`).data
                            plugin.showColumn(col)
                        } else {
                            col = this.columns.find(c => c.text === `Sem 0${i} Ano ${year}`).data
                            plugin.hideColumn(col)
                        }
                    }
                } else {
                    for (let i = 1; i <= 2; i++) {
                        let col = null
                        let foundSemester = this.year2semesters.find(s => s.id === i)
                        
                        if (foundSemester) {
                            col = this.columns.find(c => c.text === `Sem 0${i} Ano ${year}`).data
                            plugin.showColumn(col)
                        } else {
                            col = this.columns.find(c => c.text === `Sem 0${i} Ano ${year}`).data
                            plugin.hideColumn(col)
                        }
                    }
                }
            }

            if (this.year1semesters.length && this.variation.id !== 6) {
                this.year1semester = true
            }

            if (this.year2semesters.length) {
                this.year2semester = true
            }

            this.hotInstance.render()
        },

        showYear(year) {
            let plugin = this.hotInstance.getPlugin('hiddenColumns')

            if (this.variation.id === 5) {
                let col = this.columns.find(c => c.text.includes(`Total Ano Ano ${year}`)).data

                if (year === 1) {
                    if (this.startingYear) {
                        for (let c = col; c < col + 4; c++) {
                            let factor = c - col

                            if (factor === 2) {
                                if (this.absoluteVariation) {
                                    plugin.showColumn(c)
                                }
                            } else if (factor === 3) {
                                if (this.percentageVariation) {
                                    plugin.showColumn(c)
                                }
                            } else {
                                plugin.showColumn(c)
                            }
                        }
                    } else {
                        for (let c = col; c < col + 4; c++) {
                            plugin.hideColumn(c)
                        }
                    }
                } else if (year === 2) {
                    if (this.laterYear) {
                        for (let c = col; c < col + 4; c++) {
                            let factor = c - col

                            if (factor === 2) {
                                if (this.absoluteVariation) {
                                    plugin.showColumn(c)
                                }
                            } else if (factor === 3) {
                                if (this.percentageVariation) {
                                    plugin.showColumn(c)
                                }
                            } else {
                                plugin.showColumn(c)
                            }
                        }
                    } else {
                        for (let c = col; c < col + 4; c++) {
                            plugin.hideColumn(c)
                        }
                    }
                }
            } else if (this.variation.id === 4) {
                let col = this.columns.find(c => c.text.includes(`Total Ano Ano ${year}`)).data

                if (year === 1) {
                    if (this.startingYear) {
                        for (let c = col; c < col + 4; c++) {
                            let factor = c - col

                            if (factor === 2) {
                                if (this.absoluteVariation) {
                                    plugin.showColumn(c)
                                }
                            } else if (factor === 3) {
                                if (this.percentageVariation) {
                                    plugin.showColumn(c)
                                }
                            } else {
                                plugin.showColumn(c)
                            }
                        }
                    } else {
                        for (let c = col; c < col + 4; c++) {
                            plugin.hideColumn(c)
                        }
                    }
                }
            } else if (this.variation.id === 6) {
                let col = 13
                
                if (this.startingYear) {
                    for (let c = col; c < col + 4; c++) {
                        let factor = c - col

                        if (factor === 2) {
                            if (this.absoluteVariation) {
                                plugin.showColumn(c)
                            }
                        } else if (factor === 3) {
                            if (this.percentageVariation) {
                                plugin.showColumn(c)
                            }
                        } else {
                            plugin.showColumn(c)
                        }
                    }
                } else {
                    for (let c = col; c < col + 4; c++) {
                        plugin.hideColumn(c)
                    }
                }
            } else {
                let col = this.columns.find(c => c.text === `Total Ano Ano ${year}`).data
                
                if (year === 1) {
                    if (this.startingYear) {
                        plugin.showColumn(col)
                    } else {
                        plugin.hideColumn(col)
                    }
                } else if (year === 2) {
                    if (this.laterYear) {
                        plugin.showColumn(col)
                    } else {
                        plugin.hideColumn(col)
                    }
                }
            }

            this.hotInstance.render()
        },

        onResize() {
            this.windowSize = { x: window.innerWidth, y: window.innerHeight }
        },

        closeGrid() {
            if (!this.unsavedChanges) {
                this.$router.push('/')
            }
        },

        openSaveDialog() {
            this.saveDialog = true
            this.getUsers()
        },

        closeSaveDialog() {
            this.savingReport = false
            this.saveDialog = false
            this.verifiedReportDescription = 0

            if (this.selectedReport) {
                return
            } else {
                this.step = '1'
                this.selectAdmin = []
                this.selectUser = []
                this.admins = []
                this.users = []
                this.report = {
                    id: null,
                    dynamic_report: "",
                    number: null,
                    title: null,
                    text: null,
                    format: null,
                    pivotTable: {}
                }
            }
        },

        async getUsers() {
            try {
                const res = await this.$http.post(this.$ipUser + "user/list", {})
                if (res) {
                    let users = res.data.rows.sort((a, b) => {
                        let nameA = a.name.toLowerCase(),
                            nameB = b.name.toLowerCase()

                        if (nameA < nameB) return -1
                        if (nameA > nameB) return 1
                        return 0 
                    })

                    const currentUser = users.find(u => u.id_user === this.getUser.id_user)
                    this.users = users.filter(u => u.id_user !== currentUser.id_user)
                    this.admins = users
                    this.selectAdmin.push(currentUser.id_user)
                }
            } catch (err) {
                this.$fnError(err)
            }
        },

        changeSelectedAdmin(e) {
            if (!e.includes(this.getUser.id_user)){
                this.selectAdmin.push(this.getUser.id_user)
                this.$toast.error('O criador do relatório deve ser um Admin')
            }
        },

        selectAllUsers() {
            if (this.selectUser.length < this.users.length) {
                this.selectUser = []
                this.users.forEach(u => {
                    this.selectUser.push(u.id_user)
                })
            } else {
                this.selectUser = []
            }
        },

        async saveReport() {
            this.savingReport = true

            try {
                let payloadReportDescription = {
                    dynamic_report: this.report.dynamic_report,
                    report_type: '1',
                }

                const reportDescriptionRes = await this.$http.post(this.$ipDynamicReport + 'dynamic-report/create', payloadReportDescription)
                if (reportDescriptionRes) {
                    const reportId = reportDescriptionRes.data.id[0]
                    this.report.id = reportId
                    this.report.dynamic_report = reportDescriptionRes.data.description

                    this.selectedReport = reportId

                    let groupFormatted = []
                    this.selectAdmin.forEach(user => {
                        groupFormatted.push({
                            id_user: user
                        })
                    })

                    let payloadAdmin = {
                        id_dynamic_report: this.report.id,
                        admin: 1,
                        users: groupFormatted,
                    }

                    const adminRes = await this.$http.post(this.$ipDynamicReport + 'dynamic-report-user-group/create-report-group', payloadAdmin)
                    if (adminRes) {

                        if (this.selectUser.length) {
                            groupFormatted = []
                            this.selectUser.forEach(user => {
                                groupFormatted.push({
                                    id_user: user
                                })
                            })

                            let payloadUser = {
                                id_dynamic_report: this.report.id,
                                admin: 0,
                                users: groupFormatted,
                            }

                            await this.$http.post(this.$ipDynamicReport + 'dynamic-report-user-group/create-report-group', payloadUser)
                        }

                        let events = []
                        this.events.forEach(e => {
                            events.push(e.id)
                        })

                        let chartType = null
                        if (this.selection.id > 1) {
                            chartType = this.chartType
                        }

                        this.report.pivotTable = {
                            events,
                            id_account_group_version: this.payload.id_account_group_version,
                            selection: this.selection,
                            variation: this.variation,
                            absoluteVariation: this.absoluteVariation,
                            percentageVariation: this.percentageVariation,
                            subtotals: this.subtotals,
                            queryType: this.queryType,
                            yearReference: this.setYearReference(this.variation.id),
                            chartType,
                            analyseHeaders: this.analyseHeaders,
                            filters: this.filters,
                            displayFilters: this.displayFilters,
                            filterConditions: this.filterConditions,
                            year1month: this.year1month,
                            year1months: this.year1months,
                            year2month: this.year2month,
                            year2months: this.year2months,
                            year1quarter: this.year1quarter,
                            year1quarters: this.year1quarters,
                            year2quarter: this.year2quarter,
                            year2quarters: this.year2quarters,
                            year1semester: this.year1semester,
                            year1semesters: this.year1semesters,
                            year2semester: this.year2semester,
                            year2semesters: this.year2semesters,
                            startingYear: this.startingYear,
                            laterYear: this.laterYear,
                        }

                        const pivot_params_json = JSON.stringify(this.report.pivotTable)

                        let payloadSlide = {
                            slide_sequence: 1,
                            slide_title: this.report.title,
                            slide_text: this.report.text,
                            slide_css: this.report.format,
                            pivot_params_json,
                            id_dynamic_report: reportId,
                        }

                        const reportSlideRes = await this.$http.post(this.$ipDynamicReport + 'dynamic-report-slide/create', payloadSlide)
                        if (reportSlideRes) {
                            this.selectedSlide = reportSlideRes.data.id[0]

                            this.getSlides(reportId)

                            this.savingReport = false
                            this.saveDialog = false
                            this.step = '1'
                            this.selectAdmin = []
                            this.selectUser = []
                            this.$toast.success(reportSlideRes.data.msg)
                        }
                    }
                }
            } catch (err) {
                this.closeSaveDialog()
                this.$fnError(err)
            }
        },

        openImportDialog() {
            this.importDialog = true
            this.getReports()
        },

        closeImportDialog() {
            this.importDialog = false

            if (!this.analyseHeaders) {
                this.selectedReport = null
                this.selectedSlide = null
                this.gettingReports = false
                this.gettingSlides = false
                this.slides = []
            }
        },

        async getReports() {
            this.gettingReports = true

            try {
                let payload = {
                    filter: {
                        conditions: [
                            {
                                AndOr: "AND",
                                column: "id_module",
                                operator: "=",
                                value: 5
                            },
                            {
                                AndOr: "AND",
                                column: "report_type",
                                operator: "=",
                                value: 1
                            }
                        ]
                    }
                }

                let res = await this.$http.post(this.$ipDynamicReport + "dynamic-report/list", payload)
                if (res) {
                    this.reports = res.data.rows
                    this.gettingReports = false
                }
            } catch (err) {
                this.gettingReports = false
                this.$fnError(err)
            }
        },

        async getSlides(reportId) {
            this.gettingSlides = true

            try {
                let payload = {
                    filter: {
                        conditions: [
                            {
                                AndOr: "AND",
                                column: "id_dynamic_report",
                                operator: "=",
                                value: reportId
                            }
                        ]
                    }
                }

                let res = await this.$http.post(this.$ipDynamicReport + "dynamic-report-slide/list", payload)
                if (res) {
                    this.slides = res.data.rows
                    this.gettingSlides = false
                }
            } catch (err) {
                this.gettingSlides = false
                this.$fnError(err)
            }
        },

        setImportReport() {
            this.loading = true
            this.importDialog = false
            let slide = this.slides.find(s => s.id_dynamic_report_slide === this.selectedSlide)
            this.importReport(slide)
        },

        async importReport(slide) {
            let slideParams = JSON.parse(slide.pivot_params_json)

            this.report = this.reports.find(r => r.id_dynamic_report === this.selectedReport)

            this.payload.id_event = slideParams.events
            this.payload.id_account_group_version = slideParams.id_account_group_version
            this.selection = slideParams.selection
            this.variation = slideParams.variation
            this.absoluteVariation = slideParams.absoluteVariation
            this.percentageVariation = slideParams.percentageVariation
            this.subtotals = slideParams.subtotals
            this.queryType = slideParams.queryType
            this.yearReference = slideParams.yearReference
            this.chartType = slideParams.chartType
            this.analyseHeaders = slideParams.analyseHeaders
            this.filters = slideParams.filters
            this.displayFilters = slideParams.displayFilters
            this.filterConditions = slideParams.filterConditions
            this.year1month = slideParams.year1month
            this.year1months = slideParams.year1months
            this.year2month = slideParams.year2month
            this.year2months = slideParams.year2months
            this.year1quarter = slideParams.year1quarter
            this.year1quarters = slideParams.year1quarters
            this.year2quarter = slideParams.year2quarter
            this.year2quarters = slideParams.year2quarters
            this.year1semester = slideParams.year1semester
            this.year1semesters = slideParams.year1semesters
            this.year2semester = slideParams.year2semester
            this.year2semesters = slideParams.year2semesters
            this.startingYear = slideParams.startingYear
            this.laterYear = slideParams.laterYear

            this.changeVariation(this.variation)

            this.events = []
            let events = this.options.find(option => option.column === 'id_event').items
            let event = null

            if (this.multipleEvents) {
                this.payload.id_event.forEach((e, index) => {
                    let foundEvent = events.find(event => event.id === e)
                    if (index === 0) event = foundEvent
                    this.events.push(foundEvent)
                })
            } else {
                this.event1 = events.find(e => e.id === this.payload.id_event[0])
                event = this.event1
                this.event2 = events.find(e => e.id === this.payload.id_event[1])
                this.events = [this.event1, this.event2]
            }

            if (event) {
                this.payload.id_account_group_version = event.id_account_group_version
                this.year1 = event.fiscal_year
                this.yearMonthInputStart = event.year_month_input_start
                this.fiscalYearStartMonth = event.fiscal_year_start_month
                this.startingMonthIndex = event.fiscal_year_start_month - 1
                this.year2 = this.year1 + 1
            }

            // this.lastRefreshParams = {
            //     events: this.events,
            //     variation: this.variation,
            //     event1: this.event1,
            //     event2: this.event2,
            //     filterConditions: this.filterConditions,
            //     idAccountGroupVersion: event.id_account_group_version
            // }

            this.selectedDisplayFilters = this.analyseHeaders.filter(a => a.select && a.visible)

            if (this.$route.query.report) {
                window.history.replaceState({}, document.title, "" + "/sales-report")
            }

            this.importedReport = true
            this.refreshGrid()
        },

        async newSlide() {
            let events = []
            if (this.multipleEvents) {
                this.events.forEach(e => {
                    events.push(e.id)
                })
            } else {
                events.push(this.event1.id, this.event2.id)
            }

            let chartType = null
            if (this.selection.id > 1) {
                chartType = this.chartType
            }

            this.report.pivotTable = {
                events,
                id_account_group_version: this.payload.id_account_group_version,
                selection: this.selection,
                variation: this.variation,
                absoluteVariation: this.absoluteVariation,
                percentageVariation: this.percentageVariation,
                subtotals: this.subtotals,
                queryType: this.queryType,
                yearReference: this.setYearReference(this.variation.id),
                chartType,
                analyseHeaders: this.analyseHeaders,
                filters: this.filters,
                displayFilters: this.displayFilters,
                filterConditions: this.filterConditions,
                year1month: this.year1month,
                year1months: this.year1months,
                year2month: this.year2month,
                year2months: this.year2months,
                year1quarter: this.year1quarter,
                year1quarters: this.year1quarters,
                year2quarter: this.year2quarter,
                year2quarters: this.year2quarters,
                year1semester: this.year1semester,
                year1semesters: this.year1semesters,
                year2semester: this.year2semester,
                year2semesters: this.year2semesters,
                startingYear: this.startingYear,
                laterYear: this.laterYear,
            }

            const pivot_params_json = JSON.stringify(this.report.pivotTable)

            let payloadSlide = {
                id_dynamic_report: this.selectedReport,
                slide_sequence: this.reportNewSlide.number,
                slide_title: this.reportNewSlide.title,
                slide_text: this.reportNewSlide.text,
                slide_css: this.reportNewSlide.format,
                pivot_params_json,
            }

            try {
                const reportSlideRes = await this.$http.post(this.$ipDynamicReport + 'dynamic-report-slide/create', payloadSlide)
                if (reportSlideRes) {
                    this.selectedSlide = reportSlideRes.data.id[0]
                    this.getSlides(this.selectedReport)

                    this.saveSlideDialog = false
                    this.$toast.success(reportSlideRes.data.msg)
                }
            } catch (err) {
                this.$fnError(err)
            }
        },

        async copySlide() {
            try {
                let payload = {
                    id_dynamic_report_slide: this.selectedSlide,
                    id_dynamic_report: this.copySlideSelectedReport
                }

                let res = await this.$http.post(this.$ipDynamicReport + "dynamic-report-slide/copy-to-report", payload )
                if (res) {
                    this.$toast.success(res.data.msg)
                }
            } catch (err) {
                this.$fnError(err)
            }
        },

        reset() {
            this.loading = true
            this.tab = 0

            let plotly = this.$refs.plotly
            if (plotly) {
                Plotly.purge(plotly)
            }

            this.hotInstance = null

            this.events = []
            this.event1 = null
            this.event2 = null
            this.multipleEvents = true

            this.analyseHeaders = []
            this.apiResult = {
                headers: [],
                rows: [],
                columns: [],
            },
            this.filterConditions = {}
            this.filters = []
            this.displayFilters = []
            this.selectedDisplayFilters = []
            this.loadingFilters = []
            this.subtotals = false
            this.selection = {id: 1, text: 'Tabela'}
            this.variation = {id: 1, text: 'Desabilitado'}
            this.queryType = 'BI_YEAR'
            this.yearReference = null
            this.lastRefreshParams = null

            this.selectedReport = null
            this.selectedSlide = null
            this.importedReport = false

            this.year1month = true
            this.year2month = true
            this.year1quarter = true
            this.year2quarter = true
            this.year1semester = true
            this.year2semester = true
            this.startingYear = true
            this.laterYear = true
            this.year1 = null
            this.year2 = null

            this.initializePage()
        },

        async exportSheet(){
            this.exporting = true

            let filterConditions = { filter: { conditions: [] }}
            for (let filter in this.filterConditions) {
                if (this.filterConditions[filter].length) {
                    filterConditions.filter.conditions.push({
                        AndOr: "AND",
                        column: filter,
                        operator: "IN",
                        value: this.filterConditions[filter]
                    })
                }
            }

            this.hideDateColumnsBeforeExport(this.variation.id)

            // css
            let cssIndex = this.analyseHeaders.findIndex(h => h.name === 'css')
            this.analyseHeaders[cssIndex].visible = false

            // account_order
            let accountOrderIndex = this.analyseHeaders.findIndex(h => h.name === 'account_order')
            this.analyseHeaders[accountOrderIndex].visible = false

            let payload = {
                NoFormulas: true,
                calculateDifference: false,
                ResultInArray: true,
                queryType: this.queryType,
                subtotals: this.subtotals,
                columns: this.analyseHeaders,
                id_account_group_version: this.payload.id_account_group_version,
                events: this.variation.id <= 4 ? this.payload.id_event : [this.event1.id, this.event2.id],
                monthReference: this.variation.id === 6 ? this.currentMonth : null,
                yearReference: this.setYearReference(this.variation.id),
                ...filterConditions
            }

            let endpoint = this.setEndpoint()

            try {
                const res = await this.$http.post(`${this.$ipSalesPlanning}${endpoint}/report-analyse`, { ...payload })
                if (res) {
                    window.open(res.data.token)
                    this.$toast.success(res.data.msg)
                    this.exporting = false
                }
            } catch (err) {
                this.exporting = false
                this.$fnError(err)
            }
        },

        hideDateColumnsBeforeExport(variationId) {
            if (variationId < 4) {
                // year 1 months
                for (let i = 1; i <= 12; i++) {
                    let foundMonth = this.year1months.find(m => m.id === i)
                    let columnIndex = null
    
                    if (i < 10) {
                        columnIndex = this.analyseHeaders.findIndex(h => h.name === `value0${i}`)
                    } else {
                        columnIndex = this.analyseHeaders.findIndex(h => h.name === `value${i}`)
                    }
    
                    if (foundMonth) {
                        this.analyseHeaders[columnIndex].visible = true
                    } else {
                        this.analyseHeaders[columnIndex].visible = false
                    }
                }
    
                // year 2 months
                for (let i = 13; i <= 24; i++) {
                    let foundMonth = this.year2months.find(m => m.id === i - 12)
                    let columnIndex = this.analyseHeaders.findIndex(h => h.name === `value${i}`)
    
                    if (foundMonth) {
                        this.analyseHeaders[columnIndex].visible = true
                    } else {
                        this.analyseHeaders[columnIndex].visible = false
                    }
                }
    
                // year 1 quarters
                for (let i = 1; i <= 4; i++) {
                    let foundQuarter = this.year1quarters.find(q => q.id === i)
                    let columnIndex = this.analyseHeaders.findIndex(h => h.name === `year1quarter${i}`)
    
                    if (foundQuarter) {
                        this.analyseHeaders[columnIndex].visible = true
                    } else {
                        this.analyseHeaders[columnIndex].visible = false
                    }
                }
    
                // year 2 quarters
                for (let i = 1; i <= 4; i++) {
                    let foundQuarter = this.year2quarters.find(q => q.id === i)
                    let columnIndex = this.analyseHeaders.findIndex(h => h.name === `year2quarter${i}`)
    
                    if (foundQuarter) {
                        this.analyseHeaders[columnIndex].visible = true
                    } else {
                        this.analyseHeaders[columnIndex].visible = false
                    }
                }
    
                // year 1 semesters
                for (let i = 1; i <= 2; i++) {
                    let foundSemester = this.year1semesters.find(s => s.id === i)
                    let columnIndex = this.analyseHeaders.findIndex(h => h.name === `year1semester${i}`)
    
                    if (foundSemester) {
                        this.analyseHeaders[columnIndex].visible = true
                    } else {
                        this.analyseHeaders[columnIndex].visible = false
                    }
                }
    
                // year 2 semesters
                for (let i = 1; i <= 2; i++) {
                    let foundSemester = this.year2semesters.find(s => s.id === i)
                    let columnIndex = this.analyseHeaders.findIndex(h => h.name === `year2semester${i}`)
    
                    if (foundSemester) {
                        this.analyseHeaders[columnIndex].visible = true
                    } else {
                        this.analyseHeaders[columnIndex].visible = false
                    }
                }
    
                // years
                let year1Index = this.analyseHeaders.findIndex(h => h.name === 'year1')
                if (this.startingYear) {
                    this.analyseHeaders[year1Index].visible = true
                } else {
                    this.analyseHeaders[year1Index].visible = false
                }
    
                let year2Index = this.analyseHeaders.findIndex(h => h.name === 'year2')
                if (this.laterYear) {
                    this.analyseHeaders[year2Index].visible = true
                } else {
                    this.analyseHeaders[year2Index].visible = false
                }
            } else if (variationId === 4) {
                // year 1 months
                for (let i = 1; i <= 12; i++) {
                    let foundMonth = this.year1months.find(m => m.id === i)
                    
                    let index = i < 10 ? `0${i}` : i
                    let columnIndex = this.analyseHeaders.findIndex(h => h.name === (`value${index}`))

                    if (foundMonth) {
                        for (let c = columnIndex; c < columnIndex + 4; c++) {
                            let factor = c - columnIndex

                            if (factor === 2) {
                                if (this.absoluteVariation) {
                                    this.analyseHeaders[c].visible = true
                                } else {
                                    this.analyseHeaders[c].visible = false
                                }
                            } else if (factor === 3) {
                                if (this.percentageVariation) {
                                    this.analyseHeaders[c].visible = true
                                } else {
                                    this.analyseHeaders[c].visible = false
                                }
                            } else {
                                this.analyseHeaders[c].visible = true
                            }
                        }
                    } else {
                        for (let c = columnIndex; c < columnIndex + 4; c++) {
                            this.analyseHeaders[c].visible = false
                        }
                    }
                }

                // year 1 quarters
                for (let i = 1; i <= 4; i++) {
                    let foundQuarter = this.year1quarters.find(q => q.id === i)
                    let columnIndex = this.analyseHeaders.findIndex(h => h.name === (`year1quarter${i}`))

                    if (foundQuarter) {
                        for (let c = columnIndex; c < columnIndex + 4; c++) {
                            let factor = c - columnIndex

                            if (factor === 2) {
                                if (this.absoluteVariation) {
                                    this.analyseHeaders[c].visible = true
                                } else {
                                    this.analyseHeaders[c].visible = false
                                }
                            } else if (factor === 3) {
                                if (this.percentageVariation) {
                                    this.analyseHeaders[c].visible = true
                                } else {
                                    this.analyseHeaders[c].visible = false
                                }
                            } else {
                                this.analyseHeaders[c].visible = true
                            }
                        }
                    } else {
                        for (let c = columnIndex; c < columnIndex + 4; c++) {
                            this.analyseHeaders[c].visible = false
                        }
                    }
                }

                // year 1 semesters
                for (let i = 1; i <= 2; i++) {
                    let foundSemester = this.year1semesters.find(q => q.id === i)
                    let columnIndex = this.analyseHeaders.findIndex(h => h.name === (`year1semester${i}`))

                    if (foundSemester) {
                        for (let c = columnIndex; c < columnIndex + 4; c++) {
                            let factor = c - columnIndex

                            if (factor === 2) {
                                if (this.absoluteVariation) {
                                    this.analyseHeaders[c].visible = true
                                } else {
                                    this.analyseHeaders[c].visible = false
                                }
                            } else if (factor === 3) {
                                if (this.percentageVariation) {
                                    this.analyseHeaders[c].visible = true
                                } else {
                                    this.analyseHeaders[c].visible = false
                                }
                            } else {
                                this.analyseHeaders[c].visible = true
                            }
                        }
                    } else {
                        for (let c = columnIndex; c < columnIndex + 4; c++) {
                            this.analyseHeaders[c].visible = false
                        }
                    }
                }

                // year
                let year1Index = this.analyseHeaders.findIndex(h => h.name === 'year1')
                if (this.startingYear) {
                    for (let c = year1Index; c < year1Index + 4; c++) {
                        let factor = c - year1Index

                        if (factor === 2) {
                            if (this.absoluteVariation) {
                                this.analyseHeaders[c].visible = true
                            } else {
                                this.analyseHeaders[c].visible = false
                            }
                        } else if (factor === 3) {
                            if (this.percentageVariation) {
                                this.analyseHeaders[c].visible = true
                            } else {
                                this.analyseHeaders[c].visible = false
                            }
                        } else {
                            this.analyseHeaders[c].visible = true
                        }
                    }
                } else {
                    for (let c = year1Index; c < year1Index + 4; c++) {
                        this.analyseHeaders[c].visible = false
                    }
                }
            } else if (variationId === 5) {
                // year 1 months
                for (let i = 1; i <= 12; i++) {
                    let foundMonth = this.year1months.find(m => m.id === i)
                    
                    let index = i < 10 ? `0${i}` : i
                    let columnIndex = this.analyseHeaders.findIndex(h => h.name.includes(`value${index}`))

                    if (foundMonth) {
                        for (let c = columnIndex; c < columnIndex + 4; c++) {
                            let factor = c - columnIndex

                            if (factor === 2) {
                                if (this.absoluteVariation) {
                                    this.analyseHeaders[c].visible = true
                                } else {
                                    this.analyseHeaders[c].visible = false
                                }
                            } else if (factor === 3) {
                                if (this.percentageVariation) {
                                    this.analyseHeaders[c].visible = true
                                } else {
                                    this.analyseHeaders[c].visible = false
                                }
                            } else {
                                this.analyseHeaders[c].visible = true
                            }
                        }
                    } else {
                        for (let c = columnIndex; c < columnIndex + 4; c++) {
                            this.analyseHeaders[c].visible = false
                        }
                    }
                }

                // year 2 months
                for (let i = 13; i <= 24; i++) {
                    let foundMonth = this.year2months.find(m => m.id === i - 12)
                    let columnIndex = this.analyseHeaders.findIndex(h => h.name.includes(`value${i}`))

                    if (foundMonth) {
                        for (let c = columnIndex; c < columnIndex + 4; c++) {
                            let factor = c - columnIndex

                            if (factor === 2) {
                                if (this.absoluteVariation) {
                                    this.analyseHeaders[c].visible = true
                                } else {
                                    this.analyseHeaders[c].visible = false
                                }
                            } else if (factor === 3) {
                                if (this.percentageVariation) {
                                    this.analyseHeaders[c].visible = true
                                } else {
                                    this.analyseHeaders[c].visible = false
                                }
                            } else {
                                this.analyseHeaders[c].visible = true
                            }
                        }
                    } else {
                        for (let c = columnIndex; c < columnIndex + 4; c++) {
                            this.analyseHeaders[c].visible = false
                        }
                    }
                }

                // year 1 quarters
                for (let i = 1; i <= 4; i++) {
                    let foundQuarter = this.year1quarters.find(q => q.id === i)
                    let columnIndex = this.analyseHeaders.findIndex(h => h.name.includes(`year1quarter${i}`))

                    if (foundQuarter) {
                        for (let c = columnIndex; c < columnIndex + 4; c++) {
                            let factor = c - columnIndex

                            if (factor === 2) {
                                if (this.absoluteVariation) {
                                    this.analyseHeaders[c].visible = true
                                } else {
                                    this.analyseHeaders[c].visible = false
                                }
                            } else if (factor === 3) {
                                if (this.percentageVariation) {
                                    this.analyseHeaders[c].visible = true
                                } else {
                                    this.analyseHeaders[c].visible = false
                                }
                            } else {
                                this.analyseHeaders[c].visible = true
                            }
                        }
                    } else {
                        for (let c = columnIndex; c < columnIndex + 4; c++) {
                            this.analyseHeaders[c].visible = false
                        }
                    }
                }

                // year 2 quarters
                for (let i = 1; i <= 4; i++) {
                    let foundQuarter = this.year2quarters.find(q => q.id === i)
                    let columnIndex = this.analyseHeaders.findIndex(h => h.name.includes(`year2quarter${i}`))

                    if (foundQuarter) {
                        for (let c = columnIndex; c < columnIndex + 4; c++) {
                            let factor = c - columnIndex

                            if (factor === 2) {
                                if (this.absoluteVariation) {
                                    this.analyseHeaders[c].visible = true
                                } else {
                                    this.analyseHeaders[c].visible = false
                                }
                            } else if (factor === 3) {
                                if (this.percentageVariation) {
                                    this.analyseHeaders[c].visible = true
                                } else {
                                    this.analyseHeaders[c].visible = false
                                }
                            } else {
                                this.analyseHeaders[c].visible = true
                            }
                        }
                    } else {
                        for (let c = columnIndex; c < columnIndex + 4; c++) {
                            this.analyseHeaders[c].visible = false
                        }
                    }
                }

                // year 1 semesters
                for (let i = 1; i <= 2; i++) {
                    let foundSemester = this.year1semesters.find(q => q.id === i)
                    let columnIndex = this.analyseHeaders.findIndex(h => h.name.includes(`year1semester${i}`))

                    if (foundSemester) {
                        for (let c = columnIndex; c < columnIndex + 4; c++) {
                            let factor = c - columnIndex

                            if (factor === 2) {
                                if (this.absoluteVariation) {
                                    this.analyseHeaders[c].visible = true
                                } else {
                                    this.analyseHeaders[c].visible = false
                                }
                            } else if (factor === 3) {
                                if (this.percentageVariation) {
                                    this.analyseHeaders[c].visible = true
                                } else {
                                    this.analyseHeaders[c].visible = false
                                }
                            } else {
                                this.analyseHeaders[c].visible = true
                            }
                        }
                    } else {
                        for (let c = columnIndex; c < columnIndex + 4; c++) {
                            this.analyseHeaders[c].visible = false
                        }
                    }
                }

                // year 2 semesters
                for (let i = 1; i <= 2; i++) {
                    let foundSemester = this.year2semesters.find(q => q.id === i)
                    let columnIndex = this.analyseHeaders.findIndex(h => h.name.includes(`year2semester${i}`))

                    if (foundSemester) {
                        for (let c = columnIndex; c < columnIndex + 4; c++) {
                            let factor = c - columnIndex

                            if (factor === 2) {
                                if (this.absoluteVariation) {
                                    this.analyseHeaders[c].visible = true
                                } else {
                                    this.analyseHeaders[c].visible = false
                                }
                            } else if (factor === 3) {
                                if (this.percentageVariation) {
                                    this.analyseHeaders[c].visible = true
                                } else {
                                    this.analyseHeaders[c].visible = false
                                }
                            } else {
                                this.analyseHeaders[c].visible = true
                            }
                        }
                    } else {
                        for (let c = columnIndex; c < columnIndex + 4; c++) {
                            this.analyseHeaders[c].visible = false
                        }
                    }
                }

                // years
                let year1Index = this.analyseHeaders.findIndex(h => h.name === 'year1_event1')
                if (this.startingYear) {
                    for (let c = year1Index; c < year1Index + 4; c++) {
                        let factor = c - year1Index

                        if (factor === 2) {
                            if (this.absoluteVariation) {
                                this.analyseHeaders[c].visible = true
                            } else {
                                this.analyseHeaders[c].visible = false
                            }
                        } else if (factor === 3) {
                            if (this.percentageVariation) {
                                this.analyseHeaders[c].visible = true
                            } else {
                                this.analyseHeaders[c].visible = false
                            }
                        } else {
                            this.analyseHeaders[c].visible = true
                        }
                    }
                } else {
                    for (let c = year1Index; c < year1Index + 4; c++) {
                        this.analyseHeaders[c].visible = false
                    }
                }
    
                let year2Index = this.analyseHeaders.findIndex(h => h.name === 'year2_event1')
                if (this.laterYear) {
                    for (let c = year2Index; c < year2Index + 4; c++) {
                        let factor = c - year2Index

                        if (factor === 2) {
                            if (this.absoluteVariation) {
                                this.analyseHeaders[c].visible = true
                            } else {
                                this.analyseHeaders[c].visible = false
                            }
                        } else if (factor === 3) {
                            if (this.percentageVariation) {
                                this.analyseHeaders[c].visible = true
                            } else {
                                this.analyseHeaders[c].visible = false
                            }
                        } else {
                            this.analyseHeaders[c].visible = true
                        }
                    }
                } else {
                    for (let c = year2Index; c < year2Index + 4; c++) {
                        this.analyseHeaders[c].visible = false
                    }
                }
            } else if (variationId === 6) {
                // year 1 month
                let currentMonth = this.currentMonth < 10 ? `0${this.currentMonth}` : this.currentMonth
                let monthIndex = this.analyseHeaders.findIndex(h => h.name.includes(`value${currentMonth}_event1`))
                if (this.year1month) {
                    for (let c = monthIndex; c < monthIndex + 4; c++) {
                        let factor = c - monthIndex

                        if (factor === 2) {
                            if (this.absoluteVariation) {
                                this.analyseHeaders[c].visible = true
                            } else {
                                this.analyseHeaders[c].visible = false
                            }
                        } else if (factor === 3) {
                            if (this.percentageVariation) {
                                this.analyseHeaders[c].visible = true
                            } else {
                                this.analyseHeaders[c].visible = false
                            }
                        } else {
                            this.analyseHeaders[c].visible = true
                        }
                    }
                } else {
                    for (let c = monthIndex; c < monthIndex + 4; c++) {
                        this.analyseHeaders[c].visible = false
                    }
                }

                // year 1 quarter
                let currentQuarter = Math.ceil(this.currentMonth / 3)
                let quarterIndex = this.analyseHeaders.findIndex(h => h.name.includes(`year1quarter${currentQuarter}_event1`))
                if (this.year1quarter) {
                    for (let c = quarterIndex; c < quarterIndex + 4; c++) {
                        let factor = c - quarterIndex

                        if (factor === 2) {
                            if (this.absoluteVariation) {
                                this.analyseHeaders[c].visible = true
                            } else {
                                this.analyseHeaders[c].visible = false
                            }
                        } else if (factor === 3) {
                            if (this.percentageVariation) {
                                this.analyseHeaders[c].visible = true
                            } else {
                                this.analyseHeaders[c].visible = false
                            }
                        } else {
                            this.analyseHeaders[c].visible = true
                        }
                    }
                } else {
                    for (let c = quarterIndex; c < quarterIndex + 4; c++) {
                        this.analyseHeaders[c].visible = false
                    }
                }

                // year 1 semester
                let currentSemester = Math.ceil(this.currentMonth / 6)
                let semesterIndex = this.analyseHeaders.findIndex(h => h.name.includes(`year1semester${currentSemester}_event1`))
                if (this.year1semester) {
                    for (let c = semesterIndex; c < semesterIndex + 4; c++) {
                        let factor = c - semesterIndex

                        if (factor === 2) {
                            if (this.absoluteVariation) {
                                this.analyseHeaders[c].visible = true
                            } else {
                                this.analyseHeaders[c].visible = false
                            }
                        } else if (factor === 3) {
                            if (this.percentageVariation) {
                                this.analyseHeaders[c].visible = true
                            } else {
                                this.analyseHeaders[c].visible = false
                            }
                        } else {
                            this.analyseHeaders[c].visible = true
                        }
                    }
                } else {
                    for (let c = semesterIndex; c < semesterIndex + 4; c++) {
                        this.analyseHeaders[c].visible = false
                    }
                }

                // year 1
                let yearIndex = this.analyseHeaders.findIndex(h => h.name === `year1_event1`)
                if (this.startingYear) {
                    for (let c = yearIndex; c < yearIndex + 4; c++) {
                        let factor = c - yearIndex

                        if (factor === 2) {
                            if (this.absoluteVariation) {
                                this.analyseHeaders[c].visible = true
                            } else {
                                this.analyseHeaders[c].visible = false
                            }
                        } else if (factor === 3) {
                            if (this.percentageVariation) {
                                this.analyseHeaders[c].visible = true
                            } else {
                                this.analyseHeaders[c].visible = false
                            }
                        } else {
                            this.analyseHeaders[c].visible = true
                        }
                    }
                } else {
                    for (let c = yearIndex; c < yearIndex + 4; c++) {
                        this.analyseHeaders[c].visible = false
                    }
                }
            }
        },
    },
}
</script>

<style scoped>
div.v-tab {
    font-size: 12px;
}

div.v-tab.v-tab--active {
    font-weight: bold;
}

div.v-tab.v-tab--active:hover span {
    font-weight: bold;
    color: var(--primary-color);
}

div.v-tab:hover span {
    font-weight: bold;
    color: black;
}

.grabbable {
    cursor: move;
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
}

.grabbable:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
}
</style>

<style src="@/../public/styles/handsontable/handsontable.full.css"></style>
